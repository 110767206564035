// import React, { useState } from 'react';
// import Navbar from '../components/Navbar'
// import Footer from '../components/Footer'

// const Hotelcheckout = () => {

//     const [showPopover, setShowPopover] = useState(false);
//     const [adults, setAdults] = useState(1);
//     const [infants, setInfants] = useState(0);
//     const [children, setChildren] = useState(0);
//     const [rooms, setRooms] = useState(1);
//     const [activeTab, setActiveTab] = useState('Mr.');
//     const [showDialog, setShowDialog] = useState(false);
//     const [guestName, setGuestName] = useState('');
//     const [guestGender, setGuestGender] = useState('Mr.');
//     const [guests, setGuests] = useState([]);

//     const togglePopover = () => {
//       setShowPopover(!showPopover);
//     };

//     const increaseCount = (setter, value) => {
//       setter(value + 1);
//     };

//     const decreaseCount = (setter, value) => {
//       if (value > 0) setter(value - 1);
//     };

//     const guestInfoString = `${adults} Adults, ${children} Children, ${infants} Infants, ${rooms} Rooms`;

//     const handleTabClick = (tab) => {
//       setActiveTab(tab);
//     };


//     const handleAddGuestClick = () => {
//         setShowDialog(true);
//     };

//     const handleSaveGuest = () => {
//         setGuests([...guests, { name: guestName, gender: guestGender }]);
//         setGuestName('');
//         setGuestGender('Mr.');
//         setShowDialog(false);
//     };

//     const handleEditGuest = (index) => {
//         const guest = guests[index];
//         setGuestName(guest.name);
//         setGuestGender(guest.gender);
//         setGuests(guests.filter((_, i) => i !== index));
//         setShowDialog(true);
//     };

//     const handleDeleteGuest = (index) => {
//         setGuests(guests.filter((_, i) => i !== index));
//     };


//   return (
//    <>
//    <Navbar/>
//    <div className="container pt-5 pb-5">
//     <div className="row">
//         <div className="col-lg-8">
//         <div className="booking-card">
//         <div className="card-heading">
//           <div className="card-text">
//             <small>Booking Details</small>
//             <h3>Grand Canyon Horseshoe Bend</h3>
//             <div className="star-icon">
//             <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>
//             </div>
//           </div>
//           <div className="card-img">
//             <img src="assets-travel/deluxe.png" alt="" />
//           </div>
//         </div>
//         <div className="row check-details">
//           <div className="check-margin col-4 col-lg-3">
//             <h5>Check-in</h5>
//             <h3>23 August</h3>
//             <h5>12:00pm</h5>
//           </div>
//           <div className="check-margin col-4 col-lg-2">
//             <span>
//               1 Night
//             </span>
//           </div>
//           <div className="check-margin col-4 col-lg-3">
//             <h5>Check-out</h5>
//             <h3>24 August</h3>
//             <h5>12:00pm</h5>
//           </div>

//           <div className="check-margin col-12 col-lg-4">
//             <h5>Rooms & Guests</h5>
//             <h3>0 Room &  Guest</h3>
//           </div>
//         </div>
//       </div>

//       <div className="row selected-room pt-5 align-items-center">
//         <h4>Grand Canyon Horseshoe Bend</h4>
//         <div className="col-lg-3 pt-4">
//           <div className="selected-room-img">
//             <img src="assets-travel/select.png" alt="" />
//           </div>
//         </div>
//         <div className="col-lg-9">
//           <div className="amenities">
//           <div className="facility-box">
//    <div className="facility-list">
//     <h5> <i class="fa-solid fa-wifi me-2"></i>  Fast wifi - 100 Mbps</h5>
//    </div>
//    <div className="facility-list">
//     <h5> <i class="fa-solid fa-person-swimming me-2"></i>  Swimming Pool</h5>
//    </div>
//    <div className="facility-list">
//     <h5> <i class="fa-solid fa-bed me-2"></i>  Double Bed</h5>
//    </div>
//    <div className="facility-list">
//     <h5> <i class="fa-solid fa-car me-2"></i> Free Parking</h5>
//    </div>
//    <div className="facility-list">
//     <h5> <i class="fa-solid fa-smoking me-2"></i>  Smoking room</h5>
//    </div>
//           </div>
//           </div>
//         </div>
//       </div>

//       <div className="guest-detail-box pt-5">
//             <h4>Passenger Details</h4>
//       <p>
//         Booking details will be sent to the email address provided by Passenger
//       </p>
//       <div className="check-tab-box mt-4 mb-4">
//       <button
//           className={activeTab === 'Mr.' ? 'button-active' : ''}
//           onClick={() => handleTabClick('Mr.')}
//         >
//           Mr.
//         </button>
//         <button
//           className={activeTab === 'Mrs.' ? 'button-active' : ''}
//           onClick={() => handleTabClick('Mrs.')}
//         >
//           Mrs.
//         </button>
//         <button
//           className={activeTab === 'Ms.' ? 'button-active' : ''}
//           onClick={() => handleTabClick('Ms.')}
//         >
//           Ms.
//         </button>
//       </div>
//             </div>
//             <form className="row passenger-row">
//                             <div className="col-lg-6">
//                                 <div className="input-container">
//                                     <input type="text" className="form-control" placeholder=" " />
//                                     <label>First Name</label>
//                                 </div>
//                             </div>
//                             <div className="col-lg-6">
//                                 <div className="input-container">
//                                     <input type="text" className="form-control" placeholder=" " />
//                                     <label>Last Name</label>
//                                 </div>
//                             </div>
//                             <div className="col-lg-6">
//                                 <div className="input-container">
//                                     <input type="text" className="form-control" placeholder=" " />
//                                     <label>Middle Name</label>
//                                 </div>
//                             </div>
//                             <div className="col-lg-6">
//                                 <div className="input-container">
//                                     <input type="text" className="form-control" placeholder=" " />
//                                     <label>Surname</label>
//                                 </div>
//                             </div>
//                         </form>

//                         <div className="guest-list">
//                             {guests.map((guest, index) => (
//                                 <div key={index} className="guest-item">
//                                     <p>{guest.gender} {guest.name}</p>
//                                     <button onClick={() => handleEditGuest(index)}><i class="bi bi-pencil-square"></i></button>
//                                     <button onClick={() => handleDeleteGuest(index)}><i class="bi bi-trash3-fill"></i></button>
//                                 </div>
//                             ))}
//                         </div>



//                         <div className="add-guest-detail pt-3">
//                             <h4>Other Passenger</h4>
//                             <p>Booking details will be sent to the email address provided by Passenger</p>
//                             <button onClick={handleAddGuestClick}>ADD GUEST DETAIL</button>
//                         </div>



//                         {showDialog && (

//                             <div className="guest-dialog mt-3">

// <div className="check-tab-box mt-4 mb-3">
//                                     <button
//                                         className={guestGender === 'Mr.' ? 'button-active' : ''}
//                                         onClick={() => setGuestGender('Mr.')}
//                                     >
//                                         Mr.
//                                     </button>
//                                     <button
//                                         className={guestGender === 'Mrs.' ? 'button-active' : ''}
//                                         onClick={() => setGuestGender('Mrs.')}
//                                     >
//                                         Mrs.
//                                     </button>
//                                     <button
//                                         className={guestGender === 'Ms.' ? 'button-active' : ''}
//                                         onClick={() => setGuestGender('Ms.')}
//                                     >
//                                         Ms.
//                                     </button>
//                                 </div>

//                                 <h4>Add Guest Name</h4>
//                                 <input
//                                     type="text"
//                                     value={guestName}
//                                     onChange={(e) => setGuestName(e.target.value)}
//                                     placeholder="Guest Name"
//                                 /> <br />
//                                 <div className='save-guest-btn'>
//  <button onClick={handleSaveGuest}>Save</button>
//                                 </div>

//                             </div>
//                         )}

//                         <div className="guest-detail-box pt-5">
//             <h4>Add-ons</h4>
//       <p>
//       You can add your <b>Add-ons</b> here if any
//       </p>

//       <form className="add-on-box">
//                 <div className="row">
//                   <div className="col-md-4 col-sm-6">
//                     <div className="form-check">
//                       <input className="form-check-input" type="checkbox" value="" id="flexCheckMeal" />
//                       <label className="form-check-label" htmlFor="flexCheckMeal">
//                       <i class="fa-solid fa-utensils"></i> Meal
//                       </label>
//                     </div>
//                   </div>
//                   <div className="col-md-4 col-sm-6">
//                     <div className="form-check">
//                       <input className="form-check-input" type="checkbox" value="" id="flexCheckBreakfast" />
//                       <label className="form-check-label" htmlFor="flexCheckBreakfast">
//                       <i class="fa-solid fa-mug-saucer"></i>  Breakfast
//                       </label>
//                     </div>
//                   </div>
//                   <div className="col-md-4 col-sm-6">
//                     <div className="form-check">
//                       <input className="form-check-input" type="checkbox" value="" id="flexCheckWifi" />
//                       <label className="form-check-label" htmlFor="flexCheckWifi">
//                       <i class="fa-solid fa-wifi"></i>  Wi-Fi
//                       </label>
//                     </div>
//                   </div>
//                   <div className="col-md-4 col-sm-6">
//                     <div className="form-check">
//                       <input className="form-check-input" type="checkbox" value="" id="flexCheckParking" />
//                       <label className="form-check-label" htmlFor="flexCheckParking">
//                       <i class="fa-solid fa-car"></i> Parking
//                       </label>
//                     </div>
//                   </div>
//                   <div className="col-md-4 col-sm-6">
//                     <div className="form-check">
//                       <input className="form-check-input" type="checkbox" value="" id="flexCheckLateCheckout" />
//                       <label className="form-check-label" htmlFor="flexCheckLateCheckout">
//                       <i class="fa-solid fa-bath"></i>  Bathtub
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//               </form>

//       </div>

//         </div>
//         <div className="col-lg-4">
//         <div className="pay-column-card">
//             <h6>$75 <span>/night</span></h6>

// <form className="pay-date-box">
//     <div className="date-box">
//         <input type="date" className='form-control' />
//         <input type="date" className='form-control'/>
//     </div>
//     <div className="guest-info-box">
//     <div className="popover-box">
//                     <input
//                       type="text"
//                       className="form-control"
//                       value={guestInfoString}
//                       readOnly
//                       onClick={togglePopover}
//                     />
//                     {showPopover && (
//                       <div className="popover-content p-3 border mt-2">
//                         <div className="form-group d-flex justify-content-between align-items-center">
//                           <label>Adults</label>
//                           <div>
//                             <button
//                               type="button"
//                               className="btn btn-secondary btn-sm me-2"
//                               onClick={() => decreaseCount(setAdults, adults)}
//                             >
//                               -
//                             </button>
//                             <span>{adults}</span>
//                             <button
//                               type="button"
//                               className="btn btn-secondary btn-sm ms-2"
//                               onClick={() => increaseCount(setAdults, adults)}
//                             >
//                               +
//                             </button>
//                           </div>
//                         </div>
//                         <div className="form-group d-flex justify-content-between align-items-center mt-3">
//                           <label>Infants</label>
//                           <div>
//                             <button
//                               type="button"
//                               className="btn btn-secondary btn-sm me-2"
//                               onClick={() => decreaseCount(setInfants, infants)}
//                             >
//                               -
//                             </button>
//                             <span>{infants}</span>
//                             <button
//                               type="button"
//                               className="btn btn-secondary btn-sm ms-2"
//                               onClick={() => increaseCount(setInfants, infants)}
//                             >
//                               +
//                             </button>
//                           </div>
//                         </div>
//                         <div className="form-group d-flex justify-content-between align-items-center mt-3">
//                           <label>Children</label>
//                           <div>
//                             <button
//                               type="button"
//                               className="btn btn-secondary btn-sm me-2"
//                               onClick={() => decreaseCount(setChildren, children)}
//                             >
//                               -
//                             </button>
//                             <span>{children}</span>
//                             <button
//                               type="button"
//                               className="btn btn-secondary btn-sm ms-2"
//                               onClick={() => increaseCount(setChildren, children)}
//                             >
//                               +
//                             </button>
//                           </div>
//                         </div>
//                         <div className="form-group d-flex justify-content-between align-items-center mt-3">
//                           <label>Rooms</label>
//                           <div>
//                             <button
//                               type="button"
//                               className="btn btn-secondary btn-sm me-2"
//                               onClick={() => decreaseCount(setRooms, rooms)}
//                             >
//                               -
//                             </button>
//                             <span>{rooms}</span>
//                             <button
//                               type="button"
//                               className="btn btn-secondary btn-sm ms-2"
//                               onClick={() => increaseCount(setRooms, rooms)}
//                             >
//                               +
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </div>

// <div className="amount-info-box mt-3">
//     <button>Book Now</button>
//     <p>You won’t be charged yet</p>
// </div>
//               </form>

//               <div className="price-detail-box mt-3">
//           <div className="breakdown-box">
//             <h5>
//             $79 x 7 nights
//             </h5>
//             <h6>
//             $555
//             </h6>
//           </div>
//           <div className="breakdown-box">
//             <h5>Cleaning fee</h5>
//             <h6>$62</h6>
//           </div>
//           <div className="breakdown-box">
//             <h5>Service Fee</h5>
//             <h6>$83</h6>
//           </div>
//             <div className="breakdown-box">
//               <h5>Occupancy taxes and fees</h5>
//               <h6>$23 </h6>
//             </div>
//           <hr />
//           <div className="total-price breakdown-box">
//             <h5>Total Price</h5>
//             <h6>
//             $701
//             </h6>
//           </div>
//         </div>

//         </div>
//     </div>
//     </div>
//    </div>
//    <Footer/>
//    </>
//   )
// }

// export default Hotelcheckout


import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from "../AuthContext";

const Hotelcheckout = () => {

  const { apipath } = useAuth();

  const [showPopover, setShowPopover] = useState(false);
  const [adults, setAdults] = useState(1);
  const [infants, setInfants] = useState(0);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [activeTab, setActiveTab] = useState('Mr.');
  const [showDialog, setShowDialog] = useState(false);
  const [guestName, setGuestName] = useState('');
  const [guestGender, setGuestGender] = useState('Mr.');
  const [guests, setGuests] = useState([]);
  const [bookingDetails, setBookingDetails] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const hotelDetails = location.state?.hotel || {};
  const searchQuery = location.state?.searchQuery || {};

  const useremail = localStorage.getItem('userId');

  const [bookData, setBookData] = useState({
    user: useremail,
    firstname: '',
    lastname: '',
    email: '',
    phone_number: '',
    checkIndate: '',
    checkOutdate: '',
    number_of_adults: '',
    number_of_children: '',
    number_of_infants: '',
    number_of_rooms: '',
    bookingId: hotelDetails.id,
  });

  useEffect(() => {
    if (hotelDetails) {
      setBookingDetails(hotelDetails);
      setBookData(prevData => ({
        ...prevData,
        bookingId: hotelDetails.id,
      }));
    }
  }, [hotelDetails]);

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  const increaseCount = (setter, value) => {
    setter(value + 1);
  };

  const decreaseCount = (setter, value) => {
    if (value > 0) setter(value - 1);
  };

  const guestInfoString = `${adults} Adults, ${children} Children, ${infants} Infants, ${rooms} Rooms`;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAddGuestClick = () => {
    setShowDialog(true);
  };

  const handleSaveGuest = () => {
    setGuests([...guests, { name: guestName, gender: guestGender }]);
    setGuestName('');
    setGuestGender('Mr.');
    setShowDialog(false);
  };

  const handleEditGuest = (index) => {
    const guest = guests[index];
    setGuestName(guest.name);
    setGuestGender(guest.gender);
    setGuests(guests.filter((_, i) => i !== index));
    setShowDialog(true);
  };

  const handleDeleteGuest = (index) => {
    setGuests(guests.filter((_, i) => i !== index));
  };

  const getIconClass = (type) => {
    switch (type) {
      case 'wifi':
        return 'fa-solid fa-wifi';
      case 'swimming_pool':
        return 'fa-solid fa-person-swimming';
      case 'room_service':
        return 'fa-solid fa-concierge-bell'; // Example icon for room service
      case 'childcare_service':
        return 'fa-solid fa-baby'; // Example icon for childcare services
      case 'lounge':
        return 'fa-solid fa-couch'; // Example icon for lounge
      case '24_hour_front_desk':
        return 'fa-solid fa-clock'; // Example icon for 24-hour front desk
      case 'laundry':
        return 'fa-solid fa-tshirt'; // Example icon for laundry service
      case 'accessibility_mobility':
        return 'fa-solid fa-wheelchair'; // Example icon for wheelchair access
      case 'parking':
        return 'fa-solid fa-parking'; // Example icon for parking
      case 'gym':
        return 'fa-solid fa-dumbbell'; // Example icon for gym
      case 'concierge':
        return 'fa-solid fa-hands-helping'; // Example icon for concierge
      case 'restaurant':
        return 'fa-solid fa-utensils'; // Example icon for restaurant
      case 'pets_allowed':
        return 'fa-solid fa-paw'; // Example icon for pets allowed
      case 'spa':
        return 'fa-solid fa-spa'; // Example icon for spa
      case 'business_centre':
        return 'fa-solid fa-building-columns'; // Example icon for business centre
      case 'pool':
        return 'fa-solid fa-person-swimming';
      default:
        return 'bi bi-circle-fill';
    }
  };

  const Datestructure = (dateString) => {
    if (!dateString) {
      console.error("Date string is undefined or null");
      return "";
    }

    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const [year, month, day] = dateString.split('-').map(Number);

    if (!year || !month || !day) {
      console.error("Date string format is incorrect");
      return "";
    }

    const date = new Date(year, month - 1, day);
    const formattedDate = `${day} ${months[date.getMonth()]}`;

    return formattedDate;
  };

  const handleBooking = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('user', useremail);
    formData.append("firstname", bookData.firstname);
    formData.append("lastname", bookData.lastname);
    formData.append("email", bookData.email);
    formData.append("phone_number", bookData.phone_number);

    // const formattedCheckInDate = new Date(bookData.checkIndate).toISOString().split('T')[0];
    // const formattedCheckOutDate = new Date(bookData.checkOutdate).toISOString().split('T')[0];
    formData.append("checkIndate", searchQuery.checkInDate);
    formData.append("checkOutdate", searchQuery.checkOutDate);

    formData.append("bookingId", bookData.bookingId);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${apipath}/hotel-booking/`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      navigate('/thanks');
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      // Show an error message to the user
      alert('There was an issue submitting your booking. Please try again.');
    }
  };

  return (
    <>
      <Navbar />
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-lg-8">
            <div className="booking-card">
              <div className="card-heading">
                <div className="card-text">
                  <small>Booking Details</small>
                  {/* <h3>Grand Canyon Horseshoe Bend</h3> */}
                  <h3>{hotelDetails.accommodation?.name || 'Hotel Name'}</h3>
                  <div className="star-icon">
                    {[...Array(5)].map((_, i) => (
                      <i key={i} className={`bi bi-star-fill ${i < (hotelDetails.accommodation?.rating || 0) ? 'text-warning' : ''}`}></i>
                    ))}
                  </div>
                </div>
                <div className="card-img">
                  {/* <img src="assets-travel/deluxe.png" alt="" /> */}
                  <img src={hotelDetails.accommodation?.photos?.[0]?.url || 'assets-travel/deluxe.png'} alt="Hotel" />
                </div>
              </div>
              <div className="row check-details">
                <div className="check-margin col-4 col-lg-3">
                  <h5>Check-in</h5>
                  <h3>{Datestructure(hotelDetails.check_in_date)}</h3>
                  <h5>{hotelDetails.accommodation.check_in_information != null ? hotelDetails.accommodation.check_in_information.check_in_after_time : "N/A"} Hrs</h5>
                </div>
                <div className="check-margin col-4 col-lg-2">
                  <span>
                    1 Night
                  </span>
                </div>
                <div className="check-margin col-4 col-lg-3">
                  <h5>Check-out</h5>
                  <h3>{Datestructure(hotelDetails.check_out_date)}</h3>
                  <h5>{hotelDetails.accommodation.check_in_information != null ? hotelDetails.accommodation.check_in_information.check_out_before_time : "N/A"} Hrs</h5>
                </div>

                <div className="check-margin col-12 col-lg-4">
                  <h5>Rooms & Guests</h5>
                  <h3>{hotelDetails.rooms || 'No info'} Room & {hotelDetails.adults || 'No info'} Guest</h3>
                </div>
              </div>
            </div>

            <div className="row selected-room pt-5 align-items-center">
              {/* <h4>Grand Canyon Horseshoe Bend</h4> */}
              <h4>{hotelDetails.accommodation?.name || 'Hotel Name'}</h4>
              <div className="col-lg-3 pt-4">
                <div className="selected-room-img">
                  {/* <img src="assets-travel/select.png" alt="" /> */}
                  <img src={hotelDetails.accommodation?.photos?.[1]?.url || 'assets-travel/select.png'} alt="Selected Room" />
                </div>
              </div>
              <div className="col-lg-9">
                <div className="amenities">
                  <div className="facility-box">
                    {hotelDetails.accommodation?.amenities?.map((amenity, index) => (
                      <div key={index} className="facility-list">
                        <h5><i className={`fa-solid ${getIconClass(amenity.type)} me-2`}></i> {amenity.description}</h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="guest-detail-box pt-5">
              <h4>Customer Details</h4>
              <p>
                Booking details will be sent to the email address provided by Customer
              </p>
              <div className="check-tab-box mt-4 mb-4">
                <button className={activeTab === 'Mr.' ? 'button-active' : ''} onClick={() => handleTabClick('Mr.')}>Mr.</button>
                <button className={activeTab === 'Mrs.' ? 'button-active' : ''} onClick={() => handleTabClick('Mrs.')}>Mrs.</button>
                <button className={activeTab === 'Ms.' ? 'button-active' : ''} onClick={() => handleTabClick('Ms.')}>Ms.</button>
              </div>
            </div>
            <form className="row passenger-row">
              <div className="col-lg-6">
                <div className="input-container">
                  <input type="text"
                    className="form-control"
                    placeholder=" "
                    value={bookData.firstname}
                    onChange={(e) => setBookData(prevData => ({ ...prevData, firstname: e.target.value }))}
                    required
                  />
                  <label>First Name</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-container">
                  <input type="text"
                    className="form-control"
                    placeholder=" "
                    value={bookData.lastname}
                    onChange={(e) => setBookData(prevData => ({ ...prevData, lastname: e.target.value }))}
                    required
                  />
                  <label>Last Name</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-container">
                  <input type="email"
                    className="form-control"
                    placeholder=" "
                    value={bookData.email}
                    onChange={(e) => setBookData(prevData => ({ ...prevData, email: e.target.value }))}
                    required
                  />
                  <label>Email</label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="input-container">
                  <input type="text"
                    className="form-control"
                    placeholder=" "
                    value={bookData.phone_number}
                    onChange={(e) => setBookData(prevData => ({ ...prevData, phone_number: e.target.value }))}
                    required
                  />
                  <label>Phone Number</label>
                </div>
              </div>
            </form>

            <div className="guest-list">
              {guests.map((guest, index) => (
                <div key={index} className="guest-item">
                  <p>{guest.gender} {guest.name}</p>
                  <button onClick={() => handleEditGuest(index)}><i class="bi bi-pencil-square"></i></button>
                  <button onClick={() => handleDeleteGuest(index)}><i class="bi bi-trash3-fill"></i></button>
                </div>
              ))}
            </div>

            <div className="add-guest-detail pt-3">
              <h4>Other Customer</h4>
              <p>Booking details will be sent to the email address provided by Customer</p>
              <button onClick={handleAddGuestClick}>ADD GUEST DETAIL</button>
            </div>

            {showDialog && (

              <div className="guest-dialog mt-3">

                <div className="check-tab-box mt-4 mb-3">
                  <button className={guestGender === 'Mr.' ? 'button-active' : ''} onClick={() => setGuestGender('Mr.')}>Mr.</button>
                  <button className={guestGender === 'Mrs.' ? 'button-active' : ''} onClick={() => setGuestGender('Mrs.')}>Mrs.</button>
                  <button className={guestGender === 'Ms.' ? 'button-active' : ''} onClick={() => setGuestGender('Ms.')}>Ms.</button>
                </div>
                <h4>Add Guest Name</h4>
                <input
                  type="text"
                  value={guestName}
                  onChange={(e) => setGuestName(e.target.value)}
                  placeholder="Guest Name"
                /> <br />
                <div className='save-guest-btn'>
                  <button onClick={handleSaveGuest}>Save</button>
                </div>
              </div>
            )}

            <div className="guest-detail-box pt-5">
              <h4>Add-ons</h4>
              <p>
                You can add your <b>Add-ons</b> here if any
              </p>

              <form className="add-on-box">
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckMeal" />
                      <label className="form-check-label" htmlFor="flexCheckMeal">
                        <i class="fa-solid fa-utensils"></i> Meal
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckBreakfast" />
                      <label className="form-check-label" htmlFor="flexCheckBreakfast">
                        <i class="fa-solid fa-mug-saucer"></i>  Breakfast
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckWifi" />
                      <label className="form-check-label" htmlFor="flexCheckWifi">
                        <i class="fa-solid fa-wifi"></i>  Wi-Fi
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckParking" />
                      <label className="form-check-label" htmlFor="flexCheckParking">
                        <i class="fa-solid fa-car"></i> Parking
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" value="" id="flexCheckLateCheckout" />
                      <label className="form-check-label" htmlFor="flexCheckLateCheckout">
                        <i class="fa-solid fa-bath"></i>  Bathtub
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="pay-column-card">
              <h6>A$ {hotelDetails.cheapest_rate_total_amount || 'N/A'} <span>/night</span></h6>
              <form className="pay-date-box">
                <div className="date-box">
                  <input
                    type="date"
                    className='form-control'
                    defaultValue={searchQuery.checkInDate || ""}
                    onChange={(e) => setBookData(searchQuery.checkInDate)}
                    required
                    readOnly
                  />
                  <input
                    type="date"
                    className='form-control'
                    defaultValue={searchQuery.checkOutDate || ""}
                    onChange={(e) => setBookData(searchQuery.checkOutDate)}
                    required
                    readOnly
                  />
                </div>
                <div className="guest-info-box">
                  <div className="popover-box">
                    <input
                      type="text"
                      className="form-control"
                      value={guestInfoString}
                      readOnly
                      onClick={togglePopover}
                    />
                    {/* {showPopover && (
                      <div className="popover-content p-3 border mt-2">
                        <div className="form-group d-flex justify-content-between align-items-center">
                          <label>Adults</label>
                          <div>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm me-2"
                              onClick={() => decreaseCount(setAdults, adults)}
                            >
                              -
                            </button>
                            <span>{adults}</span>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm ms-2"
                              onClick={() => increaseCount(setAdults, adults)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-between align-items-center mt-3">
                          <label>Infants</label>
                          <div>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm me-2"
                              onClick={() => decreaseCount(setInfants, infants)}
                            >
                              -
                            </button>
                            <span>{infants}</span>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm ms-2"
                              onClick={() => increaseCount(setInfants, infants)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-between align-items-center mt-3">
                          <label>Children</label>
                          <div>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm me-2"
                              onClick={() => decreaseCount(setChildren, children)}
                            >
                              -
                            </button>
                            <span>{children}</span>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm ms-2"
                              onClick={() => increaseCount(setChildren, children)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-between align-items-center mt-3">
                          <label>Rooms</label>
                          <div>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm me-2"
                              onClick={() => decreaseCount(setRooms, rooms)}
                            >
                              -
                            </button>
                            <span>{rooms}</span>
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm ms-2"
                              onClick={() => increaseCount(setRooms, rooms)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>

                <div className="amount-info-box mt-3">
                  <button onClick={handleBooking}>Confirm Booking</button>
                  <p>You won't be charged yet</p>
                </div>
              </form>

              <div className="price-detail-box mt-3">
                <div className="breakdown-box">
                  <h5>
                    Basic Charge
                  </h5>
                  <h6>
                    A$ {hotelDetails.cheapest_rate_total_amount || 'N/A'}
                  </h6>
                </div>
                <div className="breakdown-box">
                  <h5>Cleaning fee</h5>
                  <h6>A$ 0</h6>
                </div>
                <div className="breakdown-box">
                  <h5>Service Fee</h5>
                  <h6>A$ 0</h6>
                </div>
                <div className="breakdown-box">
                  <h5>Occupancy taxes and fees</h5>
                  <h6>A$ 0</h6>
                </div>
                <hr />
                <div className="total-price breakdown-box">
                  <h5>Total Price</h5>
                  <h6>
                    A$ {hotelDetails.cheapest_rate_total_amount || 'N/A'}
                  </h6>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Hotelcheckout