import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useAuth } from "../AuthContext";

const About = () => {
  const { apipath } = useAuth();
  const [AboutPageContent, setAboutPageContent] = useState({
    About_Us: [],
    Know_More: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${apipath}/AboutPage-content/`)
      .then(response => {
        const {
          AboutUsObj,
          KnowMoreObj,
        } = response.data;

        setAboutPageContent({
          About_Us: AboutUsObj,
          Know_More: KnowMoreObj,
        });

        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

    const [formData, setFormData] = useState({
      name: '',
      phone_number: '',
      email: '',
      message: ''
    });
  
    const [submissionStatus, setSubmissionStatus] = useState(null);
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(`${apipath}/AboutPage_Form/`, formData);
        console.log('Form submitted successfully:', response.data);
        setSubmissionStatus('success');
        setFormData({
          name: '',
          phone_number: '',
          email: '',
          message: ''
        });
      } catch (error) {
        console.error('Error submitting form:', error.response ? error.response.data : error.message);
        setSubmissionStatus('error');
      }
    };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content.</div>;

    
  return (
    <>
      <Navbar />

      <div className="container-fluid about">
        <div className="container">
          <h1>ABOUT US</h1>
          <p>Creators Of Remarkable Travel Experiences</p>
        </div>
      </div>

      <div className="container about-content">
      {AboutPageContent.About_Us.map((item) => (
        <div className="row">
          <div className="col-lg-6">
            <div className="about-img">
              <img src={item.about_us_image1} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-right">
            <div dangerouslySetInnerHTML={{ __html: item.about_us_title1 }}/>
            <div dangerouslySetInnerHTML={{ __html: item.about_us_content1 }}/>
            </div>
          </div>
        </div>
      ))}
      </div>

      <div className="container about-content">
      {AboutPageContent.About_Us.map((item) => (
        <div className="row">
          <div className="col-lg-7">
            <div className="about-left">
            <div dangerouslySetInnerHTML={{ __html: item.about_us_title2 }}/>
            <div dangerouslySetInnerHTML={{ __html: item.about_us_content2 }}/>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-img p-4">
              <img src={item.about_us_image2} alt="" />
            </div>
          </div>
        </div>
      ))}
      </div>

      <div className="container-fluid know py-5">
        <div className="container">
          <h1>Know More About Us Through Numbers</h1>
          <p>
            Data isn't just a tool for us; it's the heartbeat of our operations.
            At Destinque, every team member is fluent in our business metrics,
            ensuring that numbers drive every decision we make.
          </p>
          <div className="row know-row">
          {AboutPageContent.Know_More.map((item) => (
            <div className="col-lg-3">
              <div className="know-card">
                <img src={item.card_image} alt="" />
                <div className="know-over">
                <div dangerouslySetInnerHTML={{ __html: item.card_text }}/>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>

      <div className="container about-contact">
        <h1>Contact Us</h1>
        <p>Send Us a Message for any query</p>
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-lg-10 mx-auto">
            <div className="row a-row">
              <div className="col-md-2 mx-auto">
                <div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-2 mx-auto">
                <div>
                  <input
                    type="text"
                    name="phone_number"
                    placeholder="Phone"
                    value={formData.phone_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-2 mx-auto">
                <div>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-2 mx-auto">
                <div>
                  <input
                    type="text"
                    name="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-2 mx-auto">
                <div>
                  <button type="submit">Contact Us</button>
                </div>
              </div>
            </div>
          </div>
          {submissionStatus === 'success' && <p>Form submitted successfully!</p>}
          {submissionStatus === 'error' && <p>Error submitting the form.</p>}
        </form>
      </div>

      <Footer />
    </>
  );
};

export default About;
