import React from 'react';

const Step5 = ({ formData, prevStep, submitForm }) => {

    return (
    <div className='container'>
        <div className='row justify-content-center'>
            <div className='col-lg-6'>
    <div className="form-step">
      <h2>Step 5 <i class="bi bi-arrow-right-short"></i> Confirm Your Preferences</h2>
      <p>Age:  <span>{formData.age}</span> </p>
      <p>Gender: <span>{formData.gender}</span> </p>
      <p>Preferred Climate: <span>{formData.climate}</span> </p>
      <p>Preferred Activities: <span>{formData.activities}</span> </p>
      <p>Budget: <span>{formData.budget}</span> </p>
<div className="ai-button">
<button className='pre-button' onClick={prevStep}>Previous</button>
<button onClick={submitForm}>Submit</button>
</div>
    </div>
     </div>
     </div>
 </div>
  );
};

export default Step5;
