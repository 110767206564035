import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Step1 from '../components/Step1Multistep';
import Step2 from '../components/Step2Multistep';
import Step3 from '../components/Step3Multistep';
import Step4 from '../components/Step4Multistep';
import Step5 from '../components/Step5Multistep';

import '../multistepform.css';

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    age: '',
    gender: '',
    climate: '',
    activities: [],
    budget: ''
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const submitForm = () => {
    // console.log('Form Data Submitted:', formData);
    navigate("/");
    // Use formData to recommend travel destinations based on user preferences
  };

  return (
    <div className="multi-outer-box">
      <video autoPlay muted loop id="bg-video">
        <source src="assets-travel/bg-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {step === 1 && <Step1 formData={formData} setFormData={setFormData} nextStep={nextStep} />}
      {step === 2 && <Step2 formData={formData} setFormData={setFormData} prevStep={prevStep} nextStep={nextStep} />}
      {step === 3 && <Step3 formData={formData} setFormData={setFormData} prevStep={prevStep} nextStep={nextStep} />}
      {step === 4 && <Step4 formData={formData} setFormData={setFormData} prevStep={prevStep} nextStep={nextStep} />}
      {step === 5 && <Step5 formData={formData} prevStep={prevStep} submitForm={submitForm} />}
    </div>
  );
};

export default MultiStepForm;
