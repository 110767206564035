import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Wishlist = () => {
  return (
  <>
  <Navbar/>
  <div className="container pt-5 pb-5">
    <div className="wishlist-heading">
        <h5>Hotel Listing</h5>
        <hr />
    </div>
    <div className="row">
        <div className="col-lg-6">

         <div className="row hotel-list-card">
    <div className="col-lg-4 wishlist-img-box">
     <img src="assets-travel/listing-hotel1.png" alt="" />
     </div>
     <div className="col-lg-8 hotel-list-content">
      <div className="content-heading">
       <div>
       <p>Entire home in Bordeaux</p>
       <h6>Bordeaux Getaway</h6>
       </div>
       <div>
     <i class="bi bi-heart-fill"></i>
       </div>
      </div>
      <div className="amenities-list">
      <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
      <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
      </div>

 <div className="rating-book">
 <div className="rating-review-list">
       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
      </div>
     <div className='book-list'>
     <button>View Details</button>
     </div>
 </div>
     </div>
  </div>
  <hr />

        </div>
        <div className="col-lg-6">
        <div className="row hotel-list-card">
    <div className="col-lg-4 wishlist-img-box">
     <img src="assets-travel/listing-hotel1.png" alt="" />
     </div>
     <div className="col-lg-8 hotel-list-content">
      <div className="content-heading">
       <div>
       <p>Entire home in Bordeaux</p>
       <h6>Bordeaux Getaway</h6>
       </div>
       <div>
     <i class="bi bi-heart-fill"></i>
       </div>
      </div>
      <div className="amenities-list">
      <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
      <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
      </div>

 <div className="rating-book">
 <div className="rating-review-list">
       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
      </div>
     <div className='book-list'>
     <button>View Details</button>
     </div>
 </div>
     </div>
  </div>
  <hr />
</div>
    </div>
  </div>

  <Footer/>
  </>
  )
}

export default Wishlist