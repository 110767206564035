import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { useAuth } from "../AuthContext";

function ExampleCarouselImage({ text, backgroundImage }) {
  const [loading, setLoading] = useState(false);
  const [bannerTab, setBannerTab] = useState("hotel");
  const [cityName, setCityName] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [guests, setGuests] = useState(1);
  const [rooms, setRooms] = useState(1);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);

  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [returnDate, setReturnDate] = useState("");

  const [passengerCount, setPassengerCount] = useState(1);
  const [cabinClass, setCabinClass] = useState("Economy");
  const [passengerType, setPassengerType] = useState("");

  const { apipath } = useAuth();

  const navigate = useNavigate();

  const checkInDateRef = useRef(null);
  const checkOutDateRef = useRef(null);
  const departureDateRef = useRef(null);

  const handleBannerTab = (tabName) => {
    setBannerTab(tabName);
  };

  const logUserActivity = async (activityData) => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        await axios.post(`${apipath}/user-activity/`, activityData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      }
      // console.log('Activity logged:', activityData);
    } catch (error) {
      console.error('Error logging user activity:', error);
    }
  };

  const handleflightSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const passengerType = 'adult';

        const formData = new FormData();
        formData.append('origin', origin);
        formData.append('destination', destination);
        formData.append('departure_date', departureDate);
        formData.append('return_date', returnDate);
        formData.append('passenger_type', passengerType);
        formData.append('passenger_count', passengerCount);
        formData.append('cabin_class', cabinClass);

        const response = await axios.post(`${apipath}/search-flights/`, formData);

        const searchOffers = response.data.data.offers;

        // Log user activity
        logUserActivity({
          activity_type: 'flight_search',
          search_query: {
            origin,
            destination,
            departureDate,
            returnDate,
            passengerType,
            passengerCount,
            cabinClass,
          },
        });

        navigate('/flightlisting', {
          state: {
            searchOffers,
            searchQuery: {
              origin,
              destination,
              departureDate,
              passengerType
            }
          }
        });
      } catch (error) {
        console.error('Error during flight search:', error);
      } finally {
        setLoading(false);
      }
    } else {
      // localStorage.setItem('redirectAfterLogin', '/hotelcheckout', { state: { hotel: hotelDetails } });
      navigate('/login');
    }
  };

  const handlehotelSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const formData = new FormData();
        formData.append('city_name', cityName);
        formData.append('check_in_date', checkInDate);
        formData.append('check_out_date', checkOutDate);
        formData.append('guests', guests);
        formData.append('rooms', rooms);

        const hotelResponse = await axios.post(`${apipath}/search-hotels/`, formData);

        const searchResults = hotelResponse.data.data.results;

        // Log user activity
        logUserActivity({
          activity_type: 'hotel_search',
          search_query:{
            cityName,
            checkInDate,
            checkOutDate,
            guests,
            rooms,
          },
        });

        navigate('/hotellisting', {
          state: {
            searchResults,
            searchQuery: {
              cityName,
              checkInDate,
              checkOutDate,
              guests,
              rooms
            }
          }
        });
      } catch (error) {
        console.error('Error during hotel search:', error);
      }finally {
        setLoading(false);
      }
    } else {
      // localStorage.setItem('redirectAfterLogin', '/hotelcheckout', { state: { hotel: hotelDetails } });
      navigate('/login');
    }
  };

  const guestRoomPopover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className="popover-content">
          <div className="popover-item">
            <label>Adults</label>
            <div className="adjust-buttons">
              <button onClick={() => setAdults(adults > 1 ? adults - 1 : 1)}>-</button>
              <span>{adults}</span>
              <button onClick={() => setAdults(adults + 1)}>+</button>
            </div>
          </div>
          <div className="popover-item">
            <label>Children</label>
            <div className="adjust-buttons">
              <button onClick={() => setChildren(children > 0 ? children - 1 : 0)}>-</button>
              <span>{children}</span>
              <button onClick={() => setChildren(children + 1)}>+</button>
            </div>
          </div>
          <div className="popover-item">
            <label>Rooms</label>
            <div className="adjust-buttons">
              <button onClick={() => setRooms(rooms > 1 ? rooms - 1 : 1)}>-</button>
              <span>{rooms}</span>
              <button onClick={() => setRooms(rooms + 1)}>+</button>
            </div>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  const flightPopover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className="popover-content">
          <div className="popover-item">
            <label>Passengers</label>
            <div className="adjust-buttons">
              <button onClick={() => setPassengerCount(passengerCount > 1 ? passengerCount - 1 : 1)}>-</button>
              <span>{passengerCount}</span>
              <button onClick={() => setPassengerCount(passengerCount + 1)}>+</button>
            </div>
          </div>
          <div className="popover-item mt-3">
            <label className='pb-1'>Cabin Class</label>
            <select className='form-select' value={cabinClass} onChange={(e) => setCabinClass(e.target.value)}>
              <option value="Economy">Economy</option>
              <option value="Business">Business</option>
              <option value="First Class">First Class</option>
            </select>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        padding: '5%',
      }}
    >
      <div className="container-fluid main">
        <div className="container">
          <div className="main-header col-lg-8">
            <h1>
              <span>Destinique International -</span> experience your unique destination
            </h1>
          </div>
          <div className="banner-tabs">
            <button className={bannerTab === "hotel" ? "active" : ""} onClick={() => handleBannerTab("hotel")}>Hotels</button>
            <button className={bannerTab === "flight" ? "active" : ""} onClick={() => handleBannerTab("flight")}>Flights</button>
            <button className={bannerTab === "car" ? "active" : ""} onClick={() => handleBannerTab("car")}>Cars</button>
            <button className={bannerTab === "curcruise" ? "active" : ""} onClick={() => handleBannerTab("curcruise")}>Cruise </button>
            <button className={bannerTab === "activity" ? "active" : ""} onClick={() => handleBannerTab("activity")}>Activity</button>
          </div>
          {bannerTab === "hotel" && (
            <div className="banner-group row">
              <div className="col-lg-3 hotel-search-icon">
                <input
                  type="text"
                  placeholder="Enter city name"
                  value={cityName}
                  onChange={(e) => setCityName(e.target.value)}
                />
                <span><i className="bi bi-search"></i></span>
              </div>
              <div className="col-lg-2"><input
                type="date"
                value={checkInDate}
                onChange={(e) => setCheckInDate(e.target.value)}
                placeholder="Check-In"
                onClick={() => checkInDateRef.current.showPicker()}
                ref={checkInDateRef}
              />
              </div>
              <div className="col-lg-2"><input
                type="date"
                value={checkOutDate}
                onChange={(e) => setCheckOutDate(e.target.value)}
                placeholder="Check-Out"
                onClick={() => checkOutDateRef.current.showPicker()}
                ref={checkOutDateRef}
              /></div>
              <div className="col-lg-3 banner-pop-column">
                <OverlayTrigger trigger="click" placement="bottom" overlay={guestRoomPopover} rootClose>
                  <input
                    type="text"
                    value={`${adults} Adults, ${children} Children, ${rooms} Rooms`}
                    readOnly
                    style={{ cursor: 'pointer' }}
                  />
                </OverlayTrigger>
              </div>
              <div className="col-lg-2 banner-button">
                <button onClick={handlehotelSearch} disabled={loading}>
                  {loading ? "Searching..." : "Search"}
                </button></div>
            </div>
          )}
          {bannerTab === "flight" && (
            <div className="banner-group row">
              <div className="col-lg-3 flight-search">
                <input
                  type="text"
                  placeholder="Enter origin"
                  value={origin}
                  onChange={(e) => setOrigin(e.target.value)}
                />
                <span><i className="bi bi-geo-alt"></i></span>
                {/* <span><i className="bi bi-search"></i></span> */}
              </div>
              <div className="col-lg-3 flight-search">
                <input
                  type="text"
                  placeholder="Enter destination"
                  value={destination}
                  onChange={(e) => setDestination(e.target.value)}
                />
                <span><i className="bi bi-geo-alt"></i></span>
                {/* <span><i className="bi bi-search"></i></span> */}
              </div>
              <div className="col-lg-2"><input
                type="date"
                value={departureDate}
                ref={departureDateRef}
                onChange={(e) => setDepartureDate(e.target.value)}
                placeholder="Departure date"
                onFocus={() => departureDateRef.current.showPicker()}
              />
              </div>
              <div className="col-lg-2">
                <OverlayTrigger trigger="click" placement="bottom" overlay={flightPopover} rootClose>
                  <input
                    type="text"
                    value={`${cabinClass}, ${passengerCount} Passenger(s)`}
                    readOnly
                    style={{ cursor: 'pointer' }}
                  />
                </OverlayTrigger>
              </div>
              <div className="col-lg-2 banner-button">
                <button onClick={handleflightSearch} disabled={loading}>
                  {loading ? "Searching..." : "Search"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExampleCarouselImage;