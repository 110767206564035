import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useAuth } from "../AuthContext";

const Career = () => {
  const { apipath } = useAuth();
  const [CareerPageContent, setCareerPageContent] = useState({
    CareerHead: [],
    CareerBenefit: [],
    CareerJob: []
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${apipath}/CareerPage-content/`)
      .then(response => {
        const {
          CareerheadObj,
          CareerbenefitObj,
          CareerjobObj,
        } = response.data;

        setCareerPageContent({
          CareerHead: CareerheadObj,
          CareerBenefit: CareerbenefitObj,
          CareerJob: CareerjobObj,
        });

        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const [show, setShow] = useState(null);

  const handleShow = (index) => {
    setShow(show === index ? null : index);
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    education: '',
    location: '',
    LinkedIn: '',
    message: '',
    pdf: null
  });

  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      pdf: e.target.files[0]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    for (let key in formData) {
      data.append(key, formData[key]);
    }

    axios.post(`${apipath}/CareerPage-ApplyForm/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log('Form submitted successfully:', response.data);
        setFormError(null);
        setFormSuccess('Form submitted successfully!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          education: '',
          location: '',
          LinkedIn: '',
          message: '',
          pdf: null
        });
        // Reset the file input field
        document.getElementById('pdfInput').value = null;
      })
      .catch(error => {
        console.error('Error submitting form:', error);
        setFormError('Error submitting form. Please try again.');
        setFormSuccess(null);
      });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content.</div>;

  return (
    <>
      <Navbar />

      <div className="container-fluid about">
        <div className="container">
          <h1>Career</h1>
          <p>Be part Of Remarkable Travel Experiences</p>
        </div>
      </div>

      <div className="container about-content">
        {CareerPageContent.CareerHead.map((item, index) => (
          <div className="row" key={index}>
            <div className="col-lg-6">
              <div className="about-img">
                <img src={item.career_headimage} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right">
                <div dangerouslySetInnerHTML={{ __html: item.career_headtitle }} />
                <p>{item.career_headcontent}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="container benefits">
        <div className="content-header activity-header">
          <h1>Benefits</h1>
          <p>Check out the perks of working with Us</p>
        </div>
        <div className="row benefit-row">
          {CareerPageContent.CareerBenefit.map((item, index) => (
            <div className="col-lg-3" key={index}>
              <div className="benefit-card">
                <div className="benefit-img">
                  <img src={item.benefits_image} alt="" />
                </div>
                <p>{item.benefits_content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="container-fluid open-p">
        <div className="container">
          <h1>Our Open Positions</h1><br />
          <div className="row open-row">
            {CareerPageContent.CareerJob.map((item, index) => item.Post_status === "show" ? (
              <div className="col-lg-12" key={index}>
                <div className="accordian-1">
                  <div className="accordian-header">
                    <h6>{item.Designation}</h6>
                    <i
                      className="bi bi-chevron-down cursor-pointer"
                      onClick={() => handleShow(index)}
                    ></i>
                  </div>
                  {show === index && (
                    <div className="accordian-content">
                      <div className="row">
                        <div className="col-lg-4 ac-items">
                          <p>
                            Location : <span>{item.Job_Location}</span>
                          </p>
                          <p>
                            Experience : <span>{item.Experience_Required}</span>
                          </p>
                        </div>
                        <div className="col-lg-4 ac-items">
                          <p>
                            Position : <span>{item.Job_Position}</span>
                          </p>
                          <p>
                            Salary : <span>{item.Salary}</span>
                          </p>
                        </div>
                        <div className="col-lg-4 ac-items">
                          <p>
                            Qualification : <span>{item.Qualification_Required}</span>
                          </p>
                          <p>
                            Type : <span>{item.Job_Type}</span>
                          </p>
                        </div>
                        <div className="col-lg-12 ac-items">
                          <p>Job Description :</p>
                          <div dangerouslySetInnerHTML={{ __html: item.Job_Description }} />
                        </div>
                      </div>
                      <div className="ac-button">
                        <button data-bs-toggle="modal" data-bs-target="#exampleModal1">Apply</button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : null
            )}
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModal1Label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="login-head">
                <h5>Apply Now!</h5>
              </div>
              <form className="row login-form" onSubmit={handleSubmit}>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className='form-control'
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className='form-control'
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="text"
                    className='form-control'
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="education">Education</label>
                  <input
                    type="text"
                    className='form-control'
                    name="education"
                    value={formData.education}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="location">Location</label>
                  <input
                    type="text"
                    className='form-control'
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <label htmlFor="LinkedIn">LinkedIn</label>
                  <input
                    type="text"
                    className='form-control'
                    name="LinkedIn"
                    value={formData.LinkedIn}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  <label htmlFor="message">Message</label>
                  <textarea
                    className='form-control'
                    rows={2}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-12 file-input mb-3">
                  <label htmlFor="pdf">Resume</label>
                  <input
                    type="file"
                    className='form-control'
                    accept=".pdf, .docx"
                    name="pdf"
                    id="pdfInput"
                    onChange={handleFileChange}
                  />
                </div>
                {formError && <p className="text-danger mt-3">{formError}</p>}
                {formSuccess && <p className="text-success mt-3">{formSuccess}</p>}
                <div className="p-button">
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Career;
