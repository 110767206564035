import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import iziToast from "izitoast";
import { useAuth } from "../AuthContext";

const Contact = () => {
  const { apipath } = useAuth();
  const [ContactPage_Contactdetail, setContactPage_Contactdetail] = useState([]);
  const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${apipath}/ContactPage-content/`)
            .then(response => {
              setContactPage_Contactdetail(response.data.ContactdetailObj);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone_number: '',
      address: '',
      message: ''
    });
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await fetch(`${apipath}/ContactForm/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        if (response.status === 201) {
          iziToast.success({
            title: 'Success',
            message: 'Submit successful',
            position: 'topCenter',
            timeout: 2000,
          });
          navigate('/');
        } else if (response.status === 400) {
          iziToast.error({
            title: 'ERROR: Invalid Credentials.',
            position: 'topCenter',
          });
        } else {
          iziToast.error({
            title: 'Error',
            message: 'Please try again later.',
            position: 'topCenter',
          });
        }
      } catch (error) {
       
      }
    };


  return (
    <>
      <Navbar />

      <div className="container-fluid about">
        <div className="container">
          <h1>
            Support <img src="assets-travel/support.png" alt="" />
          </h1>
          <p>How can we help?</p>
        </div>
      </div>

      <div className="container support">
        <div className="row">
          {ContactPage_Contactdetail.map((item) => (
          <div className="col-lg-5 support-left">
            <h1>{item.contactdetail1_title}</h1>
            <p>{item.contactdetail2_subtitle}</p>

            <div className="support-left-list">
              <li>
                <i className="bi bi-telephone mr-2"></i>{" "}
                <a href="tel:+91 811 484 8666" className="text-decoration-none text-white">
                  {item.contactdetail3_phone}
                </a>
              </li>
              <li>
                <i className="bi bi-envelope mr-2"></i>{" "}
                <a href="mailto:sales@narayanaluminium.com" className="text-decoration-none text-white">
                {item.contactdetail4_email}
                </a>
              </li>
              <li>
                <i className="bi bi-geo-alt-fill mr-2"></i> {item.contactdetail5_address}
              </li>
              <li>
                <i className="bi bi-globe mr-2"></i>{" "}
                <a
                  href="https://destinqueAustralia.com"
                  className="text-decoration-none text-white"
                >
                  {item.contactdetail6_weblink}
                </a>
              </li>
            </div>
          </div>
          ))}

          <div className="col-lg-7 support-right">
            <h1>Get In Touch With Us</h1>
            <p>
              We are happy to answer any questions you may have regarding our
              services.
            </p>

            <form className="row support-right-row" onSubmit={handleSubmit}>
              <div className="col-lg-6">
                <div className="support-item">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    minLength="2"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="support-item">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="support-item">
                  <label htmlFor="phone_number">Phone</label>
                  <input
                    type="text"
                    name="phone_number"
                    placeholder="Phone"
                    value={formData.phone_number}
                    onChange={handleChange}
                    minLength="10" 
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="support-item">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                    maxLength="10"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="support-item">
                  <label htmlFor="message">Message</label>
                  <input
                    type="text"
                    name="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    maxLength="15"
                  />
                </div>
              </div>
              <div className="support-button">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;