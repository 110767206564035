import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { FaCheckCircle } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Step3 = ({ formData, setFormData, prevStep, nextStep }) => {
  const [loading, setLoading] = useState(true);

  const activities = [
    { name: 'Beach', image: 'assets-travel/beach.jpg' },
    { name: 'Hiking', image: 'assets-travel/hiking.jpg' },
    { name: 'Cultural', image: 'assets-travel/cultural.jpg' },
    { name: 'Adventure', image: 'assets-travel/adventure.jpg' },
    { name: 'Relaxation', image: 'assets-travel/relax.jpg' },
    { name: 'Wildlife', image: 'assets-travel/wildlife.jpg' },
  ];

  const handleSelectActivity = (activity) => {
    setFormData({ ...formData, activities: activity });
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate loading time, adjust as needed

    return () => clearTimeout(timer);
  }, []);

  return (
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8'>
            <div className="form-step">
              <h2>Step 3 <i className="bi bi-arrow-right-short"></i> What activities do you enjoy?</h2>
              {loading ? (
                <div className="shimmer">
                  <Slider {...sliderSettings}>
                    {activities.map((activity, index) => (
                      <div key={index} className="shimmer-slide"></div>
                    ))}
                  </Slider>
                </div>
              ) : (
                <Slider {...sliderSettings}>
                  {activities.map((activity) => (
                    <div key={activity.name} className="activity-slide" onClick={() => handleSelectActivity(activity.name)}>
                      <img 
                        src={activity.image} 
                        alt={activity.name} 
                        className={`activity-img2 ${formData.activities === activity.name ? 'selected' : ''}`} 
                      />
                      <p className="activity-name">{activity.name}</p>
                      {formData.activities === activity.name && (
                        <FaCheckCircle className="check-icon" />
                      )}
                    </div>
                  ))}
                </Slider>
              )}
              <div className="ai-button">
                <button className='pre-button' onClick={prevStep}>Previous</button>
                <button onClick={nextStep}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Step3;
