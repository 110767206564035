// import React, { useState } from 'react';
// import Navbar from '../components/Navbar';
// import Footer from '../components/Footer';
// import ReactSlider from 'react-slider';

// const Flightlisting = () => {

//   const [priceRange, setPriceRange] = useState([500, 10000]);


//   const handlePriceChange = (values) => {
//     setPriceRange(values);
//   };


//   return (
//     <>
//       <Navbar />

//       <div className="container pt-4 mt-2">
//         <div className="list-heading">
//           <div className="sort-content">
//             <h3>Best Flexible flight booking in Destinique Australia</h3>
//             <h6>20+ Flights Found in Sydney, Australia</h6>
//           </div>
//           <div className="sort-select">
//             <h6>Sort by:</h6>
//             <div>
//               <select className="form-select" aria-label="Default select example">
//                 <option defaultValue="Popularity">Popularity</option>
//                 <option value="1">Rating</option>
//                 <option value="2">Star</option>
//                 <option value="3">View</option>
//               </select>
//             </div>
//           </div>
//         </div>
//         <div className="row pt-4">
//           <div className="col-lg-3">
//             <div className="filter-column">
//               <div className="availability-filter">
//                 <h5>Availability</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <div className="mb-3">
//                     <label htmlFor="">Leaving From</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Sydney, Australia"
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">Going To</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Goa, India"
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">Date</label>
//                     <input type="date" className="form-control" />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">Departure Time</label>
//                     <input type="time" className="form-control" />
//                   </div>
//                   <div className="avail-btn">
//                     <button>Check Availability</button>
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Price</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <label>
//                     <span>AUD {priceRange[0]}</span> - <span>AUD {priceRange[1]}</span>
//                   </label>
//                   <div className="range-input">
//                     <ReactSlider
//                       className="horizontal-slider"
//                       thumbClassName="thumb"
//                       trackClassName="track"
//                       min={500}
//                       max={10000}
//                       value={priceRange}
//                       onChange={handlePriceChange}
//                       minDistance={100}
//                     />
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Day</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Monday
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Tuesday
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Wednesday
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Thursday
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Friday
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Saturday
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Airline</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Air Asia
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Emirates
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Indigo
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Air Asia X
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Cabin</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Economy
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Basic Economy
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Business
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                   <div className="check-filter">
//                     <div class="form-check">
//                       <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
//                       <label class="form-check-label" for="flexCheckDefault">
//                         Mixed
//                       </label>
//                     </div>
//                     <span>(123)</span>
//                   </div>
//                 </form>
//               </div>

//             </div>
//           </div>

//           <div className="col-lg-9 pt-2">
//             <div className="flight-column">
//               <div className="flight-heading">
//                 <div className='flight-place'>
//                   <h6> <span>Australia</span> <span><i class="bi bi-arrow-right-short"></i></span> <span>Indonesia</span> </h6>
//                   <h4> $ 800 </h4>
//                 </div>
//                 <div className="flight-btn">
//                   <button>Book Now</button>
//                 </div>
//               </div>
//               <div className="row flight-inner-row">
//                 <div className="col-lg-1">
//                   <div className="flight-name-box">
//                     <img src="assets-travel/indigo.png" width={32} height={32} alt="" />
//                     <h6>IndiGo</h6>
//                     <p>6E-6168 <br />
//                       <span>Economy</span></p>
//                   </div>
//                 </div>
//                 <div className="col-lg-3">
//                   <div className="flight-info">
//                     <h6>17:55</h6>
//                     <p>Thu, 8 Aug 2024</p>
//                     <h5>Sydney International Airport,
//                       Terminal 1, Australia</h5>
//                   </div>
//                 </div>
//                 <div className="col-lg-2">
//                   <div className="flight-time-took">
//                     <span><i class="bi bi-clock"></i></span>
//                     <p>1h 10min</p>
//                   </div>
//                 </div>
//                 <div className="col-lg-3">
//                   <div className="flight-info">
//                     <h6>17:55</h6>
//                     <p>Thu, 8 Aug 2024</p>
//                     <h5>Sydney International Airport,
//                       Terminal 1, Australia</h5>
//                   </div>
//                 </div>
//                 <div className="col-lg-1"></div>
//               </div>
//             </div>
//             <div className="flight-column">
//               <div className="flight-heading">
//                 <div className='flight-place'>
//                   <h6> <span>Australia</span> <span><i class="bi bi-arrow-right-short"></i></span> <span>Indonesia</span> </h6>
//                   <h4> $ 800 </h4>
//                 </div>
//                 <div className="flight-btn">
//                   <button>Book Now</button>
//                 </div>
//               </div>
//               <div className="row flight-inner-row">
//                 <div className="col-lg-1">
//                   <div className="flight-name-box">
//                     <img src="assets-travel/indigo.png" width={32} height={32} alt="" />
//                     <h6>IndiGo</h6>
//                     <p>6E-6168 <br />
//                       <span>Economy</span></p>
//                   </div>
//                 </div>
//                 <div className="col-lg-3">
//                   <div className="flight-info">
//                     <h6>17:55</h6>
//                     <p>Thu, 8 Aug 2024</p>
//                     <h5>Sydney International Airport,
//                       Terminal 1, Australia</h5>
//                   </div>
//                 </div>
//                 <div className="col-lg-2">
//                   <div className="flight-time-took">
//                     <span><i class="bi bi-clock"></i></span>
//                     <p>1h 10min</p>
//                   </div>
//                 </div>
//                 <div className="col-lg-3">
//                   <div className="flight-info">
//                     <h6>17:55</h6>
//                     <p>Thu, 8 Aug 2024</p>
//                     <h5>Sydney International Airport,
//                       Terminal 1, Australia</h5>
//                   </div>
//                 </div>
//                 <div className="col-lg-1"></div>
//               </div>
//             </div>
//             <div className="flight-column">
//               <div className="flight-heading">
//                 <div className='flight-place'>
//                   <h6> <span>Australia</span> <span><i class="bi bi-arrow-right-short"></i></span> <span>Indonesia</span> </h6>
//                   <h4> $ 800 </h4>
//                 </div>
//                 <div className="flight-btn">
//                   <button>Book Now</button>
//                 </div>
//               </div>
//               <div className="row flight-inner-row">
//                 <div className="col-lg-1">
//                   <div className="flight-name-box">
//                     <img src="assets-travel/indigo.png" width={32} height={32} alt="" />
//                     <h6>IndiGo</h6>
//                     <p>6E-6168 <br />
//                       <span>Economy</span></p>
//                   </div>
//                 </div>
//                 <div className="col-lg-3">
//                   <div className="flight-info">
//                     <h6>17:55</h6>
//                     <p>Thu, 8 Aug 2024</p>
//                     <h5>Sydney International Airport,
//                       Terminal 1, Australia</h5>
//                   </div>
//                 </div>
//                 <div className="col-lg-2">
//                   <div className="flight-time-took">
//                     <span><i class="bi bi-clock"></i></span>
//                     <p>1h 10min</p>
//                   </div>
//                 </div>
//                 <div className="col-lg-3">
//                   <div className="flight-info">
//                     <h6>17:55</h6>
//                     <p>Thu, 8 Aug 2024</p>
//                     <h5>Sydney International Airport,
//                       Terminal 1, Australia</h5>
//                   </div>
//                 </div>
//                 <div className="col-lg-1"></div>
//               </div>
//             </div>
//             <div className="flight-column">
//               <div className="flight-heading">
//                 <div className='flight-place'>
//                   <h6> <span>Australia</span> <span><i class="bi bi-arrow-right-short"></i></span> <span>Indonesia</span> </h6>
//                   <h4> $ 800 </h4>
//                 </div>
//                 <div className="flight-btn">
//                   <button>Book Now</button>
//                 </div>
//               </div>
//               <div className="row flight-inner-row">
//                 <div className="col-lg-1">
//                   <div className="flight-name-box">
//                     <img src="assets-travel/indigo.png" width={32} height={32} alt="" />
//                     <h6>IndiGo</h6>
//                     <p>6E-6168 <br />
//                       <span>Economy</span></p>
//                   </div>
//                 </div>
//                 <div className="col-lg-3">
//                   <div className="flight-info">
//                     <h6>17:55</h6>
//                     <p>Thu, 8 Aug 2024</p>
//                     <h5>Sydney International Airport,
//                       Terminal 1, Australia</h5>
//                   </div>
//                 </div>
//                 <div className="col-lg-2">
//                   <div className="flight-time-took">
//                     <span><i class="bi bi-clock"></i></span>
//                     <p>1h 10min</p>
//                   </div>
//                 </div>
//                 <div className="col-lg-3">
//                   <div className="flight-info">
//                     <h6>17:55</h6>
//                     <p>Thu, 8 Aug 2024</p>
//                     <h5>Sydney International Airport,
//                       Terminal 1, Australia</h5>
//                   </div>
//                 </div>
//                 <div className="col-lg-1"></div>
//               </div>
//             </div>
//           </div>

//         </div>
//       </div>

//       <Footer />
//     </>
//   )
// }

// export default Flightlisting;



import React, { useState, useMemo } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ReactSlider from 'react-slider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../AuthContext";

const Flightlisting = () => {

  const location = useLocation();
  const { apipath } = useAuth();
  const { searchOffers } = location.state || {};
  const [searchQuery, setSearchQuery] = useState({
    origin: location.state?.searchQuery?.origin || '',
    destination: location.state?.searchQuery?.destination || '',
    departureDate: location.state?.searchQuery?.departureDate || ''
  });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [priceRange, setPriceRange] = useState([0, 5000]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedAirlines, setSelectedAirlines] = useState([]);
  const [selectedCabins, setSelectedCabins] = useState([]);


  const handlePriceChange = (values) => {
    setPriceRange(values);
  };

  // Function to format the date
  function formatDate(isoString) {
    const date = new Date(isoString);

    const dateOptions = { weekday: 'short', day: '2-digit', month: 'short', year: '2-digit' };
    return date.toLocaleDateString('en-GB', dateOptions); // "Sun, 11 Aug 24"
  }

  // Function to format the time
  function formatTime(isoString) {
    const date = new Date(isoString);

    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    return date.toLocaleTimeString('en-GB', timeOptions); // "07:44"
  }

  // Function to format ISO 8601 duration
  function formatDuration(duration) {
    // Remove the 'PT' prefix
    duration = duration.slice(2);

    // Initialize variables for hours and minutes
    let hours = 0;
    let minutes = 0;

    // Extract hours and minutes using regex
    const hoursMatch = duration.match(/(\d+)H/);
    const minutesMatch = duration.match(/(\d+)M/);

    if (hoursMatch) {
      hours = parseInt(hoursMatch[1], 10);
    }
    if (minutesMatch) {
      minutes = parseInt(minutesMatch[1], 10);
    }

    // Format the duration string
    let formattedDuration = '';
    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }
    if (minutes > 0) {
      formattedDuration += `${minutes} min`;
    }

    return formattedDuration.trim();
  }

  const handleDayChange = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };

  const handleAirlineChange = (airline) => {
    setSelectedAirlines((prevAirlines) =>
      prevAirlines.includes(airline)
        ? prevAirlines.filter((a) => a !== airline)
        : [...prevAirlines, airline]
    );
  };

  const handleCabinChange = (cabin) => {
    setSelectedCabins((prevCabins) =>
      prevCabins.includes(cabin)
        ? prevCabins.filter((c) => c !== cabin)
        : [...prevCabins, cabin]
    );
  };

  // Extract unique airlines and count flights for each airline
  const airlineCounts = useMemo(() => {
    const airlineMap = new Map();
    searchOffers?.forEach((offer) => {
      offer.slices.forEach((slice) => {
        const airlineName = slice.segments[0].marketing_carrier.name;
        airlineMap.set(airlineName, (airlineMap.get(airlineName) || 0) + 1);
      });
    });
    return Array.from(airlineMap.entries());
  }, [searchOffers]);

  // Count flights for each day of the week
  const dayCounts = useMemo(() => {
    const dayMap = new Map();
    searchOffers?.forEach((offer) => {
      offer.slices.forEach((slice) => {
        const dayOfWeek = new Date(slice.segments[0].departing_at).toLocaleDateString('en-GB', { weekday: 'long' });
        dayMap.set(dayOfWeek, (dayMap.get(dayOfWeek) || 0) + 1);
      });
    });
    return Array.from(dayMap.entries());
  }, [searchOffers]);

  // Count flights for each cabin class
  const cabinCounts = useMemo(() => {
    const cabinMap = new Map();
    searchOffers?.forEach((offer) => {
      offer.slices.forEach((slice) => {
        const cabinName = slice.segments[0].passengers[0].cabin.marketing_name;
        cabinMap.set(cabinName, (cabinMap.get(cabinName) || 0) + 1);
      });
    });
    return Array.from(cabinMap.entries());
  }, [searchOffers]);

  // Filtering Logic
  const filteredOffers = useMemo(() => {
    return searchOffers
      ?.filter((offer) => {
        const totalPrice = offer.total_amount;
        return totalPrice >= priceRange[0] && totalPrice <= priceRange[1];
      })
      ?.filter((offer) => {
        if (selectedDays.length === 0) return true;

        return offer.slices.some((slice) => {
          const dayOfWeek = new Date(slice.segments[0].departing_at).toLocaleDateString('en-GB', { weekday: 'long' });
          return selectedDays.includes(dayOfWeek);
        });
      })
      ?.filter((offer) => {
        if (selectedAirlines.length === 0) return true;

        return offer.slices.some((slice) => {
          const airlineName = slice.segments[0].marketing_carrier.name;
          return selectedAirlines.includes(airlineName);
        });
      })
      ?.filter((offer) => {
        if (selectedCabins.length === 0) return true;

        return offer.slices.some((slice) => {
          const cabinName = slice.segments[0].passengers[0].cabin.marketing_name;
          return selectedCabins.includes(cabinName);
        });
      });
  }, [searchOffers, priceRange, selectedDays, selectedAirlines, selectedCabins]);

  const handleInputChange = (e) => {
    setSearchQuery({ ...searchQuery, [e.target.name]: e.target.value });
  };

  const fetchSearchOffers = async (query) => {
    const passengerType = 'adult';
    const formData = new FormData();
    formData.append('origin', query.origin);
    formData.append('destination', query.destination);
    formData.append('departure_date', query.departureDate);
    formData.append('passenger_type', passengerType);

    const response = await fetch(`${apipath}/search-flights/`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to fetch search results');
    }

    const data = await response.json();
    return data.data.offers; // Adjust this according to your API response structure
  };

  const handleCheckAvailability = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Replace with the actual API call to fetch search results based on the searchQuery
      const updatedsearchOffers = await fetchSearchOffers(searchQuery);
      navigate(`/flightlisting`, {
        state: {
          searchOffers: updatedsearchOffers,
          searchQuery: searchQuery
        }
      });
    } catch (error) {
      console.error("Error fetching updated search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleflightDetails = (flightId) => {
    navigate(`/flightcheckout`, { 
      state: { 
        offer: searchOffers.find(offer => offer.id === flightId),
        searchQuery: searchQuery
      } });
  };

  return (
    <>
      <Navbar />

      <div className="container pt-4 mt-2">
        <div className="list-heading">
          <div className="sort-content">
            <h3>Best Flexible flight booking on Destinique International</h3>
            <h6>{filteredOffers ? `${filteredOffers.length} Flights Found` : 'No Flights Found'}</h6>
          </div>
          <div className="sort-select">
            <h6>Sort by:</h6>
            <div>
              <select className="form-select" aria-label="Default select example">
                <option defaultValue="Popularity">Popularity</option>
                <option value="1">Rating</option>
                <option value="2">Star</option>
                <option value="3">View</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-lg-3">
            <div className="filter-column">
              <div className="availability-filter">
                <h5>Availability</h5>
                <hr />
                <form className="filter-form" onSubmit={handleCheckAvailability}>
                  <div className="mb-3">
                    <label htmlFor="">Leaving From</label>
                    <input
                      type="text"
                      className="form-control"
                      name="origin"
                      value={searchQuery.origin}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="">Going To</label>
                    <input
                      type="text"
                      className="form-control"
                      name="destination"
                      value={searchQuery.destination}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="">Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="departureDate"
                      value={searchQuery.departureDate}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* <div className="mb-3">
                    <label htmlFor="">Departure Time</label>
                    <input type="time" className="form-control" />
                  </div> */}
                  <div className="avail-btn">
                    <button type="submit" disabled={loading}>
                      {loading ? "Checking..." : "Check Availability"}
                    </button>
                  </div>
                </form>
              </div>

              <div className="availability-filter">
                <h5>Price</h5>
                <hr />
                <form className="filter-form">
                  <label>
                    <span>A$ {priceRange[0]}</span> - <span>A$ {priceRange[1]}</span>
                  </label>
                  <div className="range-input">
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      min={0}
                      max={5000}
                      value={priceRange}
                      onChange={handlePriceChange}
                      minDistance={100}
                    />
                  </div>
                </form>
              </div>

              <div className="availability-filter">
                <h5>Day</h5>
                <hr />
                <form className="filter-form">
                  {dayCounts.map(([day, count]) => (
                    <div className="check-filter" key={day}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`day-${day}`}
                          value={day}
                          checked={selectedDays.includes(day)}
                          onChange={() => handleDayChange(day)}
                        />
                        <label className="form-check-label" htmlFor={`day-${day}`}>
                          {day}
                        </label>
                      </div>
                      <span>({count})</span>
                    </div>
                  ))}
                </form>
              </div>

              <div className="availability-filter">
                <h5>Airline</h5>
                <hr />
                <form className="filter-form">
                  {airlineCounts.map(([airline, count]) => (
                    <div className="check-filter" key={airline}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`airline-${airline}`}
                          value={airline}
                          checked={selectedAirlines.includes(airline)}
                          onChange={() => handleAirlineChange(airline)}
                        />
                        <label className="form-check-label" htmlFor={`airline-${airline}`}>
                          {airline}
                        </label>
                      </div>
                      <span>({count})</span>
                    </div>
                  ))}
                </form>
              </div>

              <div className="availability-filter">
                <h5>Cabin Class</h5>
                <hr />
                <form className="filter-form">
                  {cabinCounts.map(([cabin, count]) => (
                    <div className="check-filter" key={cabin}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`cabin-${cabin}`}
                          value={cabin}
                          checked={selectedCabins.includes(cabin)}
                          onChange={() => handleCabinChange(cabin)}
                        />
                        <label className="form-check-label" htmlFor={`cabin-${cabin}`}>
                          {cabin}
                        </label>
                      </div>
                      <span>({count})</span>
                    </div>
                  ))}
                </form>
              </div>

            </div>
          </div>

          <div className="col-lg-9 pt-2">
            {filteredOffers && filteredOffers.length > 0 ? (
              filteredOffers.map((offer, index) => (
                <div key={index}>
                  {offer.slices.map((slice, sliceIndex) =>
                    <div key={sliceIndex}>
                      <div className="flight-column">
                        <div className="flight-heading">
                          <div className='flight-place'>
                            <h6> <span>{slice.origin.city_name}</span> <span><i class="bi bi-arrow-right-short"></i></span> <span>{slice.destination.city_name}</span> </h6>
                            <h4>A$ {offer.total_amount} </h4>
                            {slice.segments[0].marketing_carrier.name === 'Duffel Airways' && (
                              <div className="ai-icon">
                                <p>
                                  <img src="" alt="" className='me-2' /> AI Recommended
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="flight-btn">
                            <button onClick={() => handleflightDetails(offer.id)}>Book Now</button>
                          </div>
                        </div>
                        <div className="row flight-inner-row">
                          <div className="col-lg-1">
                            <div className="flight-name-box">
                              {/* <img src="assets-travel/indigo.png" width={32} height={32} alt="" /> */}
                              <img src={slice.segments[0].marketing_carrier.logo_symbol_url} width={40} height={50} alt="" />
                              <h6>{slice.segments[0].marketing_carrier.name}</h6>
                              <p>{slice.segments[0].operating_carrier_flight_number} <br />
                                <span>{slice.segments[0].passengers[0].cabin.marketing_name}</span></p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="flight-info">
                              <h6>{formatTime(slice.segments[0].departing_at)}</h6>
                              <p>{formatDate(slice.segments[0].departing_at)}</p>
                              <h5>{slice.origin.name}, Terminal {slice.segments[0].origin_terminal || 'N/A'}, {slice.origin.city_name}</h5>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="flight-time-took">
                              <span><i class="bi bi-clock"></i></span>
                              <p>{formatDuration(slice.duration)}</p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="flight-info">
                              <h6>{formatTime(slice.segments[0].arriving_at)}</h6>
                              <p>{formatDate(slice.segments[0].arriving_at)}</p>
                              <h5>{slice.destination.name}, Terminal {slice.segments[0].destination_terminal || 'N/A'}, {slice.destination.city_name}</h5>
                            </div>
                          </div>
                          <div className="col-lg-1"></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No flights available</p>
            )}
          </div>

        </div>
      </div>

      <Footer />
    </>
  )
}

export default Flightlisting;