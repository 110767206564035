import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { FaCheckCircle } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Step2 = ({ formData, setFormData, prevStep, nextStep }) => {
  const [loading, setLoading] = useState(true);

  const climates = [
    { name: 'Tropical', image: 'assets-travel/tropical.jpg' },
    { name: 'Temperate', image: 'assets-travel/temprate.jpg' },
    { name: 'Cold', image: 'assets-travel/cold.jpg' },
    { name: 'Desert', image: 'assets-travel/desert.jpg' },
    { name: 'Polar', image: 'assets-travel/polar.jpg' },
    { name: 'Mediterranean', image: 'assets-travel/medi.jpg' },
  ];

  const handleSelectClimate = (climate) => {
    setFormData({ ...formData, climate });
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate loading time, adjust as needed

    return () => clearTimeout(timer);
  }, []);

  return (
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-8'>
            <div className="form-step">
              <h2>Step 2 <i className="bi bi-arrow-right-short"></i> What climate do you prefer?</h2>
              {loading ? (
                <div className="shimmer">
                  <Slider {...sliderSettings}>
                    {climates.map((climate, index) => (
                      <div key={index} className="shimmer-slide"></div>
                    ))}
                  </Slider>
                </div>
              ) : (
                <Slider {...sliderSettings}>
                  {climates.map((climate) => (
                    <div key={climate.name} className="climate-slide" onClick={() => handleSelectClimate(climate.name)}>
                      <img 
                        src={climate.image} 
                        alt={climate.name} 
                        className={`climate-img ${formData.climate === climate.name ? 'selected' : ''}`} 
                      />
                      <p className="climate-name">{climate.name}</p>
                      {formData.climate === climate.name && (
                        <FaCheckCircle className="check-icon" />
                      )}
                    </div>
                  ))}
                </Slider>
              )}
              <div className="ai-button">
                <button className='pre-button' onClick={prevStep}>Previous</button>
                <button onClick={nextStep}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Step2;
