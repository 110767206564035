import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";
// import iziToast from "izitoast";

const Footer = () => {
  const { apipath } = useAuth();
  const [Footer, setFooter] = useState([]);
  // const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${apipath}/Footer/`)
            .then(response => {
              setFooter(response.data.FooterObj);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    }, []);

    const [formData, setFormData] = useState({
      subscriber_email: ''
    });
  
    const [submissionStatus, setSubmissionStatus] = useState(null);
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(`${apipath}/FooterForm/`, formData);
        // console.log('Form submitted successfully:', response.data);
        setSubmissionStatus('success');
        setFormData({
          subscriber_email: ''
        });
      } catch (error) {
        console.error('Error submitting form:', error.response ? error.response.data : error.message);
        setSubmissionStatus('error');
      }
    };

  return (
    <>
      <div className="container-fluid footer">
        <div className="container">
          <div className="row footer-row">
            <div className="col-lg-4">
            {Footer.map((item) => (
              <div className="footer-content">
                <div className="footer-logo">
                  <img src={item.footer_logo} alt="" />
                </div>
                <p>{item.footer_catchline}</p>
                <div className="footer-social">
                  <i className="bi bi-facebook"></i>
                  <i className="bi bi-twitter"></i>
                  <i className="bi bi-instagram"></i>
                </div>
              </div>
            ))}
            </div>
            <div className="col-lg-2">
              <div className="footer-list">
                <h3>Quick Links</h3>
                <Link to="/about" className="text-decoration-none">
                  <li>About Us</li>
                </Link>
                <Link to="/career" className="text-decoration-none">
                  <li>Jobs</li>
                </Link>
                <Link to="/privacy" className="text-decoration-none">
                  <li>Privacy Policy</li>
                </Link>
                <Link to="/support" className="text-decoration-none">
                  <li>Support</li>
                </Link>
                <Link to="/contact" className="text-decoration-none">
                  <li>Contact</li>
                </Link>
              </div>
            </div>
            <div className="col-lg-3">
            {Footer.map((item) => (
              <div className="footer-list">
                <h3>Contact Us</h3>
                <li>
                  <i className="bi bi-geo-alt-fill"></i> {item.footer_address}
                </li>
                <li>
                  <i className="bi bi-telephone"></i> {item.footer_phone_number}
                </li>
                <li>
                  <i className="bi bi-envelope"></i>{" "}
                  <a
                    href="mailto:support@destinque.com"
                    className="text-decoration-none text-white"
                  >
                    {item.footer_email}
                  </a>
                </li>
              </div>
            ))}
            </div>
            <div className="col-lg-3">
              <div className="footer-list">
                <h6>Have questions? We're here to assist you!</h6>
                <form onSubmit={handleSubmit}>
                  <div className="input-group footer-group">
                    <input
                      type="email"
                      name="subscriber_email"
                      className="form-control"
                      placeholder="Enter your Email"
                      aria-label="email"
                      aria-describedby="basic-addon2"
                      value={formData.subscriber_email}
                      onChange={handleChange}
                      required
                    />
                    <div className="input-group-append footer-append">
                      <button 
                      className="input-group-text" 
                      id="basic-addon2" type="submit" 
                      style={{ backgroundColor: 'yellow'}}>
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
                {submissionStatus === 'success' && <p style={{ color: 'yellow' }}>Subscription successful!</p>}
                {submissionStatus === 'error' && <p style={{ color: 'yellow' }}>Subscription failed. Please try again.</p>}
              </div>
            </div>
          </div>
          <div className="row footer-row2">
          {Footer.map((item) => (
            <div className="col-lg-12">
              <div dangerouslySetInnerHTML={{ __html: item.footer_copyright_line }}/>
            </div>
          ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
