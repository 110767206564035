import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const Login = () => {
  const { login, apipath } = useAuth();
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [otpError, setOtpError] = useState('');
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const otpRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    otpRefs.current = Array(6).fill().map((_, i) => otpRefs.current[i] || React.createRef());
  }, []);

  const handleOtpButtonClick = async () => {
    if (!showOtpBox) {
      try {
        const response = await axios.post(`${apipath}/send-otp/`, { email });
        if (response.status === 200) {
          setShowOtpBox(true);
        }
      } catch (error) {
        console.error('Error sending OTP:', error.response ? error.response.data : error.message);
      }
    } else if (showOtpBox && !showDetailsForm) {
      try {
        const otpCode = otp.join('');
        const response = await axios.post(`${apipath}/verify-otp/`, { email, otp_code: otpCode });

        if (response.status === 200) {
          const { requires_completion, refresh, access } = response.data;
          const userId = response.data.user_id; // Ensure this value is correctly retrieved

          login(access, `${firstName} ${lastName}`, userId);

          if (requires_completion) {
            setShowDetailsForm(true);
          } else {
            const userResponse = await axios.get(`${apipath}/user-details/`, {
              headers: { 'Authorization': `Bearer ${access}` }
            });

            const profile = userResponse.data.ProfileObj;

            if (profile && profile.email === email) {
              login(access, `${profile.first_name} ${profile.last_name}`, profile.User, email);
              const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
              localStorage.removeItem('redirectAfterLogin');
              navigate(redirectTo);
            } else {
              console.error('Profile not found for email:', email);
              setOtpError('Profile not found');
            }
          }
        } else {
          setOtpError('Invalid OTP');
        }
      } catch (error) {
        setOtpError('Error verifying OTP');
        console.error('Error verifying OTP:', error.response ? error.response.data : error.message);
      }
    } else if (showDetailsForm) {
      handleDetailsSubmit();
    }
  };


  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const newOtp = [...otp];
    newOtp[index] = value;

    if (value && index < otpRefs.current.length - 1) {
      otpRefs.current[index + 1].current.focus();
    }

    setOtp(newOtp);
  };

  const handleDetailsSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      const response = await axios.post(`${apipath}/complete-registration/`, {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        user_address: userAddress,
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        login(localStorage.getItem('token'), firstName + ' ' + lastName, response.data.user_id, email);
        navigate("/multistepform");
        alert('Registration complete.');
      }
    } catch (error) {
      console.error('Error completing registration:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className='outer-body'>
      <div className="container">
        <div className="row login-row">
          <h4>Sign in to your account</h4>
          <div className="col-lg-5 pt-3">
            <form className="login-form" onSubmit={showDetailsForm ? handleDetailsSubmit : (e) => e.preventDefault()}>
              <div className="login-box mb-3">
                <label>Enter Your Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder='Enter your email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={showOtpBox || showDetailsForm}
                />
                <span><i className="bi bi-envelope"></i></span>
              </div>

              {showOtpBox && !showDetailsForm && (
                <div className="otp-box mb-4">
                  <label>Enter OTP</label>
                  <div className="otp-input">
                    {otpRefs.current.map((ref, index) => (
                      <input
                        key={index}
                        type="text"
                        className="form-control"
                        maxLength="1"
                        ref={ref}
                        value={otp[index]}
                        onChange={(e) => handleOtpChange(e, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    ))}
                  </div>
                  {otpError && <p className="text-danger">{otpError}</p>}
                  <h5>
                    Didn't receive code? <span onClick={handleOtpButtonClick}>Resend again</span>
                  </h5>
                </div>
              )}

              {showDetailsForm && (
                <div className="details-form">
                  <div className="mb-3">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label>Address</label>
                    <textarea
                      className="form-control"
                      value={userAddress}
                      onChange={(e) => setUserAddress(e.target.value)}
                      required
                    />
                  </div>
                </div>
              )}

              <button className="btn btn-primary w-100" type="button" onClick={handleOtpButtonClick}>
                {showOtpBox && !showDetailsForm ? 'Verify OTP' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
