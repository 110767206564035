import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const apipath = "http://localhost:8000/api";
  const apipath = "http://test.dgtlinnovations.in:8080/api";
  
  const login = (access, username, emailId, userId) => {
    localStorage.setItem('token', access);
    localStorage.setItem('username', username); // Save username
    localStorage.setItem('email', emailId);
    localStorage.setItem('userId', userId);
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
    setIsLoggedIn(false);
    window.location.href = "/";
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, apipath, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
