import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import HotelListing from "./pages/HotelListing";
import MultiStepForm from "./pages/MultiStepForm";
import Hoteldetail from "./pages/Hoteldetail";
import Hotelcheckout from "./pages/Hotelcheckout";
import Test from "./pages/Test";
import Wishlist from "./pages/Wishlist";
import Flightlisting from "./pages/Flightlisting";
import Flightcheckout from "./pages/Flightcheckout";
import Login from "./pages/Login";
import Bookings from "./pages/Bookings";
import Thankyou from "./pages/Thanks";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/support" element={<Contact />} />
          <Route exact path="/hotellisting" element={<HotelListing />} />
          <Route exact path="/multistepform" element={<MultiStepForm />} />
          <Route exact path="/hotel-details" element={<Hoteldetail/>} />
          {/* <Route exact path="/hoteldetail" element={<Hoteldetail/>} /> */}
          <Route exact path="/hotelcheckout" element={<Hotelcheckout/>} />
          <Route exact path="/wishlist" element={<Wishlist/>} />
          <Route exact path="/flightlisting" element={<Flightlisting/>} />
          <Route exact path="/flightcheckout" element={<Flightcheckout/>} />
          <Route exact path="/test" element={<Test/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/book" element={<Bookings/>} />
          <Route exact path="/thanks" element={<Thankyou/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
