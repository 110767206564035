import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';

const Hoteldetail = () => {

  const [showPopover, setShowPopover] = useState(false);
  const [adults, setAdults] = useState(1);
  const [infants, setInfants] = useState(0);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);

  const navigate = useNavigate();

  const location = useLocation();
  const hotelDetails = location.state?.hotel || {};
  const searchQuery = location.state?.searchQuery || {};

  // useEffect(() => {
  //   if (hotelDetails.accommodation) {
  //     console.log("Hotel Details from Location State:", hotelDetails);
  //   }
  // }, [hotelDetails]);

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  const increaseCount = (setter, value) => {
    setter(value + 1);
  };

  const decreaseCount = (setter, value) => {
    if (value > 0) setter(value - 1);
  };

  const guestInfoString = `${adults} Adults, ${children} Children, ${infants} Infants, ${rooms} Rooms`;

  const handleCheckoutDetails = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/hotelcheckout', {
        state: { 
          hotel: hotelDetails,
          searchQuery: searchQuery
        }
      });
    } else {
      // localStorage.setItem('redirectAfterLogin', '/hotelcheckout', { state: { hotel: hotelDetails } });
      navigate('/login');
    }
  };

  const getIconClass = (type) => {
    switch (type) {
      case 'wifi':
        return 'fa-solid fa-wifi';
      case 'swimming_pool':
        return 'fa-solid fa-person-swimming';
      case 'room_service':
        return 'fa-solid fa-concierge-bell'; // Example icon for room service
      case 'childcare_service':
        return 'fa-solid fa-baby'; // Example icon for childcare services
      case 'lounge':
        return 'fa-solid fa-couch'; // Example icon for lounge
      case '24_hour_front_desk':
        return 'fa-solid fa-clock'; // Example icon for 24-hour front desk
      case 'laundry':
        return 'fa-solid fa-tshirt'; // Example icon for laundry service
      case 'accessibility_mobility':
        return 'fa-solid fa-wheelchair'; // Example icon for wheelchair access
      case 'parking':
        return 'fa-solid fa-parking'; // Example icon for parking
      case 'gym':
        return 'fa-solid fa-dumbbell'; // Example icon for gym
      case 'concierge':
        return 'fa-solid fa-hands-helping'; // Example icon for concierge
      case 'restaurant':
        return 'fa-solid fa-utensils'; // Example icon for restaurant
      case 'pets_allowed':
        return 'fa-solid fa-paw'; // Example icon for pets allowed
      case 'spa':
        return 'fa-solid fa-spa'; // Example icon for spa
      case 'business_centre':
        return 'fa-solid fa-building-columns'; // Example icon for business centre
      case 'pool':
        return 'fa-solid fa-person-swimming';
      default:
        return 'bi bi-circle-fill';
    }
  };

  const maplongitude = hotelDetails.accommodation.location.geographic_coordinates.longitude || {};
  const maplatitude = hotelDetails.accommodation.location.geographic_coordinates.latitude || {};
  const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;
  if (!mapboxToken) {
    console.error('Mapbox access token is not set');
    return <div>Error: Mapbox access token is not set.</div>;
  }
  const mapImageUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+555555(${maplongitude},${maplatitude})/${maplongitude},${maplatitude},14,0/600x600?access_token=${mapboxToken}`;

  return (
    <>
      <Navbar />
      <div className="container pt-4">
        {hotelDetails.accommodation ? (
          // hotelDetails.map((hotel) => (
          <div>
            <div className="hotel-name">
              {/* <h3>Grand Canyon Horseshoe Bend</h3> */}
              <h3>{hotelDetails.accommodation.name || 'No name available'}</h3>
              <div className='small-info'>
                <p> <span><i class="bi bi-star-fill"></i>  {hotelDetails.accommodation.rating || 'No rating available'}</span>
                  <span className='span'> ({'100'} reviews) </span>
                  <span className='line-span'> {hotelDetails.accommodation.location.address.city_name || 'City Name not found'}
                    {/*, {hotelDetails.accommodation.location.address.country_code || 'City Name not found'}*/}</span> </p>
                <h6><i class="bi bi-heart"></i> Save </h6>
              </div>
            </div>
            <div className="row image-container">
              <div className="col-lg-6 col-left">
                <div className="detail-img">
                  {/* <img src="assets-travel/detail.png" alt="" /> */}
                  <img src={hotelDetails.accommodation.photos[0]?.url || "assets-travel/detail.png"} alt={hotelDetails.accommodation.name || 'Hotel image'} />
                </div>
              </div>
              <div className="col-lg-6 col-right">
                <div className="grid-container">
                  <div className="grid-item">
                    {/* <img src="assets-travel/detail1.png" alt="" /> */}
                    <img src={hotelDetails.accommodation.photos[1]?.url || "assets-travel/detail1.png"} alt={hotelDetails.accommodation.name || 'Hotel image'} />
                  </div>
                  <div className="grid-item">
                    {/* <img src="assets-travel/detail2.png" alt="" /> */}
                    <img src={hotelDetails.accommodation.photos[2]?.url || "assets-travel/detail2.png"} alt={hotelDetails.accommodation.name || 'Hotel image'} />
                  </div>
                  <div className="grid-item">
                    {/* <img src="assets-travel/detail3.png" alt="" /> */}
                    <img src={hotelDetails.accommodation.photos[3]?.url || "assets-travel/detail3.png"} alt={hotelDetails.accommodation.name || 'Hotel image'} />
                  </div>
                  <div className="grid-item">
                    {/* <img src="assets-travel/detail4.png" alt="" /> */}
                    <img src={hotelDetails.accommodation.photos[4]?.url || "assets-travel/detail4.png"} alt={hotelDetails.accommodation.name || 'Hotel image'} />
                    <div className="button-over-image">
                      <button>
                        <i className="bi bi-camera-fill"></i> See all photos
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-lg-8">
                <div className="hotel-detail-content">
                  <div className="detail-name">
                    <h4>
                      {hotelDetails.accommodation.name || 'No name available'}
                    </h4>
                    {/* <p> 2 queen bed - Dedicated bathroom</p> */}
                  </div>
                  <div className="short-review-box mt-5">
                    <div>
                      <img src="assets-travel/guest-cup.png" width={96} height={84} alt="" />
                    </div>
                    <div>
                      <p>
                        One of the most loved hotel on <b>Destinique Australia</b> <br /> in{" "}
                        by our Guests
                      </p>
                    </div>
                    <div className="num-box">
                      <h6>{hotelDetails.accommodation.ratings?.[0]?.value || '0'}</h6>
                      <span>
                        <div className="star-icon">
                          {[...Array(5)].map((_, i) => (
                            <i key={i} className={`bi bi-star-fill ${i < (hotelDetails.accommodation?.rating || 0) ? 'text-warning' : ''}`}></i>
                          ))}
                        </div>
                      </span>
                    </div>
                    <div className="num-box">
                      <h6>100</h6>
                      <span>Reviews</span>
                    </div>
                  </div>

                  <div className="about-hotel pt-5">
                    <h5>About this place</h5>
                    {/* <p className="mt-3">Come and stay in this superb Grand Canyon Horseshoe Bend, in the heart of the historic center of Bordeaux.
                      Spacious and bright, in a real Bordeaux building in exposed stone, you will enjoy all the charms of the city thanks to its ideal location. Close to many shops, bars and restaurants, you can access the apartment by tram A and C and bus routes 27 and 44</p> */}
                    <p>{hotelDetails.accommodation.description || 'No description available'}</p>
                  </div>

                  {/* <div className="amenities pt-4">
                    <h4>Amenities We offer</h4>
                    <div className="facility-box">
                      {hotelDetails.accommodation.amenities.map((amenities) => (
                      <div className="facility-list">
                        <h5><i class="bi bi-circle-fill me-2"></i>{amenities.description || 'Not available'}</h5>
                      </div>
                      ))}
                    </div>
                  </div> */}
                  <div className="amenities pt-4">
                    <h4>Amenities We offer</h4>
                    <div className="facility-box">
                      {hotelDetails.accommodation.amenities.map((amenity, index) => (
                        <div key={index} className="facility-list">
                          <h5>
                            <i className={`${getIconClass(amenity.type)} me-2`}></i>
                            {amenity.description || 'Not available'}
                          </h5>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="pay-column-card">
                  <h6>A$ {hotelDetails.cheapest_rate_total_amount || 'N/A'} <span>/night</span></h6>
                  <form className="pay-date-box">
                    <div className="date-box">
                      <input 
                      type="date" 
                      className='form-control' 
                      defaultValue={searchQuery.checkInDate || ""}
                      readOnly
                      />
                      <input 
                      type="date" 
                      className='form-control' 
                      defaultValue={searchQuery.checkOutDate || ""}
                      readOnly
                      />
                    </div>
                    <div className="guest-info-box">
                    <div className="popover-box">
                      <input
                        type="text"
                        className="form-control"
                        value={guestInfoString}
                        readOnly
                        onClick={togglePopover} // This allows the popover to still open
                      />
                      {/* {showPopover && (
                        <div className="popover-content p-3 border mt-2">
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label>Adults</label>
                            <div>
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm me-2"
                                onClick={() => decreaseCount(setAdults, adults)}
                              >
                                -
                              </button>
                              <span>{adults}</span>
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm ms-2"
                                onClick={() => increaseCount(setAdults, adults)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="form-group d-flex justify-content-between align-items-center mt-3">
                            <label>Infants</label>
                            <div>
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm me-2"
                                onClick={() => decreaseCount(setInfants, infants)}
                              >
                                -
                              </button>
                              <span>{infants}</span>
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm ms-2"
                                onClick={() => increaseCount(setInfants, infants)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="form-group d-flex justify-content-between align-items-center mt-3">
                            <label>Children</label>
                            <div>
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm me-2"
                                onClick={() => decreaseCount(setChildren, children)}
                              >
                                -
                              </button>
                              <span>{children}</span>
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm ms-2"
                                onClick={() => increaseCount(setChildren, children)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                          <div className="form-group d-flex justify-content-between align-items-center mt-3">
                            <label>Rooms</label>
                            <div>
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm me-2"
                                onClick={() => decreaseCount(setRooms, rooms)}
                              >
                                -
                              </button>
                              <span>{rooms}</span>
                              <button
                                type="button"
                                className="btn btn-secondary btn-sm ms-2"
                                onClick={() => increaseCount(setRooms, rooms)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>


                    <div className="amount-info-box mt-3">
                      {/* <button>Book Now</button> */}
                      <button onClick={() => handleCheckoutDetails(hotelDetails.id)}>Book Now</button>
                      <p>You won’t be charged yet</p>
                    </div>
                  </form>

                  <div className="price-detail-box mt-3">
                    {/* <div className="breakdown-box">
                      <h5>
                        Best Price
                      </h5>
                      <h6>
                        A$ {hotelDetails.accommodation.cheapest_rate_total_amount || 'N/A'}
                      </h6>
                    </div>
                    <div className="breakdown-box">
                      <h5>Cleaning fee</h5>
                      <h6>$62</h6>
                    </div>
                    <div className="breakdown-box">
                      <h5>Service Fee</h5>
                      <h6>$83</h6>
                    </div>
                    <div className="breakdown-box">
                      <h5>Occupancy taxes and fees</h5>
                      <h6>$23 </h6>
                    </div> */}
                    <hr />
                    <div className="total-price breakdown-box">
                      <h5>Best Price</h5>
                      <h6>
                        A$ {hotelDetails.cheapest_rate_total_amount || 'N/A'}
                      </h6>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="row selection-row pt-5">
              <h3>Select your room</h3>
              <div className="col-lg-6">
                <div className="room-select-column">
                  <div className="radio-select-box pb-2">
                    <label class="form-check-label" for="flexRadioDefault1">
                      Deluxe Room
                    </label>
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  </div>

                  <div className='room-detail-box'>
                    <div className="inner-img-box">
                      <img src="assets-travel/deluxe.png" alt="" />
                    </div>
                    <div className="amenities pt-4">
                      <div className="facility-box1">
                        {/* {hotelDetails.accommodation.amenities.map((amenity, index) => (
                        <div key={index} className="facility-list">
                          <h5>
                            <i className={`${getIconClass(amenity.type)} me-2`}></i>
                            {amenity.description || 'Not available'}
                          </h5>
                        </div>
                      ))} */}
                        <div className="facility-list1">
                          <h5> <i class="fa-solid fa-wifi me-2"></i>  Fast wifi - 100 Mbps</h5>
                        </div>
                        <div className="facility-list1">
                          <h5> <i class="fa-solid fa-person-swimming me-2"></i>  Swimming Pool</h5>
                        </div>
                        <div className="facility-list1">
                          <h5> <i class="fa-solid fa-bed me-2"></i>  Double Bed</h5>
                        </div>
                        <div className="facility-list1">
                          <h5> <i class="fa-solid fa-car me-2"></i> Free Parking</h5>
                        </div>
                        <div className="facility-list1">
                          <h5> <i class="fa-solid fa-smoking me-2"></i>  Smoking room</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-lg-6">
                <div className="room-select-column ">
                  <div className="radio-select-box pb-2">
                    <label class="form-check-label" for="flexRadioDefault1">
                      Classic Room
                    </label>
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  </div>

                  <div className='room-detail-box'>
                    <div className="inner-img-box">
                      <img src="assets-travel/deluxe.png" alt="" />
                    </div>
                    <div className="amenities pt-4">
                      <div className="facility-box1">
                        <div className="facility-list1">
                          <h5> <i class="fa-solid fa-wifi me-2"></i>  Fast wifi - 100 Mbps</h5>
                        </div>
                        <div className="facility-list1">
                          <h5> <i class="fa-solid fa-person-swimming me-2"></i>  Swimming Pool</h5>
                        </div>
                        <div className="facility-list1">
                          <h5> <i class="fa-solid fa-bed me-2"></i>  Double Bed</h5>
                        </div>
                        <div className="facility-list1">
                          <h5> <i class="fa-solid fa-car me-2"></i> Free Parking</h5>
                        </div>
                        <div className="facility-list1">
                          <h5> <i class="fa-solid fa-smoking me-2"></i>  Smoking room</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="row map-row pt-4">
              <h3>Where you will be</h3>
              <div className="col-lg-6">
                <div className="map-box">
                  <iframe src={mapImageUrl} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12765.887402923154!2d-111.5103628!3d36.8790588!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87346c8d22566b09%3A0x45637089634f1702!2sHorseshoe%20Bend!5e0!3m2!1sen!2sin!4v1723195986846!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="map-place-detail">
                  <h5>{hotelDetails.accommodation.name || 'No name available'}</h5><br />
                  {/* <p className='pt-2'>Come and stay in this superb Grand Canyon Horseshoe Bend, in the heart of the historic center of
                    Bordeaux.Spacious and bright, in a real Bordeaux building in exposed stone, you will enjoy all the charms of the
                    city thanks to its ideal location. Close to many shops, bars and restaurants, you can access the apartment by
                    tram A and C and bus routes 27 and 44</p> */}
                  <div className="row place-map-info">
                    <div className="row-lg-6">
                      <i class="bi bi-geo-alt me-2"></i>
                      {hotelDetails.accommodation.location.address.line_one || 'No name available'},
                      {hotelDetails.accommodation.location.address.city_name || 'No name available'},
                      {hotelDetails.accommodation.location.address.country_code || 'No name available'},
                      Postal Code:- {hotelDetails.accommodation.location.address.postal_code || 'No name available'}
                    </div>
                    <div className="row-lg-6"><br />
                      <i class="bi bi-telephone-plus me-2"></i>+{hotelDetails.accommodation.phone_number || 'Not available'}
                    </div>
                    <div className="row-lg-6"><br />
                      <i class="bi bi-envelope me-2"></i> {hotelDetails.accommodation.email || 'Not available'}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br /><br />


            <div className="hotel-review-container mt-5 pb-5">
              <h3>Reviews</h3>
              <div className="row">
                <div className="col-lg-4">
                  <div className="review-column">
                    <div className="review-header">
                      <img className="review-avatar" src="assets-travel/review.png" width={40} height={40} alt="" />
                      <div className="review-details">
                        <h6 className="review-name">Akash Rawat</h6>
                        <p className="review-date">August 2, 2024</p>
                      </div>
                    </div>
                    <div className="review-rating">

                      <span> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>  </span>

                    </div>
                    <p className="review-text">
                      Great hotel, very clean and friendly staff. Would
                      definitely come back!
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="review-column">
                    <div className="review-header">
                      <img className="review-avatar" src="assets-travel/review.png" width={40} height={40} alt="" />
                      <div className="review-details">
                        <h6 className="review-name">Akash Rawat</h6>
                        <p className="review-date">August 2, 2024</p>
                      </div>
                    </div>
                    <div className="review-rating">

                      <span> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>  </span>

                    </div>
                    <p className="review-text">
                      Great hotel, very clean and friendly staff. Would
                      definitely come back!
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="review-column">
                    <div className="review-header">
                      <img className="review-avatar" src="assets-travel/review.png" width={40} height={40} alt="" />
                      <div className="review-details">
                        <h6 className="review-name">Akash Rawat</h6>
                        <p className="review-date">August 2, 2024</p>
                      </div>
                    </div>
                    <div className="review-rating">

                      <span> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>  </span>

                    </div>
                    <p className="review-text">
                      Great hotel, very clean and friendly staff. Would
                      definitely come back!
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        ) : (
          <p>No hotels found.</p>
        )}

      </div>
      <Footer />
    </>
  )
}

export default Hoteldetail


// import React, { useEffect, useRef, useState } from 'react';
// import Navbar from '../components/Navbar';
// import Footer from '../components/Footer';
// import { useLocation, useNavigate } from 'react-router-dom';
// import mapboxgl from 'mapbox-gl'; // Import Mapbox GL JS

// const Hoteldetail = () => {

//   const [showPopover, setShowPopover] = useState(false);
//   const [adults, setAdults] = useState(1);
//   const [infants, setInfants] = useState(0);
//   const [children, setChildren] = useState(0);
//   const [rooms, setRooms] = useState(1);

//   const navigate = useNavigate();

//   const location = useLocation();
//   const hotelDetails = location.state?.hotel || {};
//   const mapContainerRef = useRef(null); // Reference for the map container

//   // useEffect(() => {
//   //   if (hotelDetails.accommodation) {
//   //     console.log("Hotel Details from Location State:", hotelDetails);
//   //   }
//   // }, [hotelDetails]);

//   const togglePopover = () => {
//     setShowPopover(!showPopover);
//   };

//   const increaseCount = (setter, value) => {
//     setter(value + 1);
//   };

//   const decreaseCount = (setter, value) => {
//     if (value > 0) setter(value - 1);
//   };

//   const guestInfoString = `${adults} Adults, ${children} Children, ${infants} Infants, ${rooms} Rooms`;

//   const handleCheckoutDetails = () => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       navigate('/hotelcheckout', { state: { hotel: hotelDetails } });
//     } else {
//       // localStorage.setItem('redirectAfterLogin', '/hotelcheckout', { state: { hotel: hotelDetails } });
//       navigate('/login');
//     }
//   };

//   const getIconClass = (type) => {
//     switch (type) {
//       case 'wifi':
//         return 'fa-solid fa-wifi';
//       case 'swimming_pool':
//         return 'fa-solid fa-person-swimming';
//       case 'room_service':
//         return 'fa-solid fa-concierge-bell'; // Example icon for room service
//       case 'childcare_service':
//         return 'fa-solid fa-baby'; // Example icon for childcare services
//       case 'lounge':
//         return 'fa-solid fa-couch'; // Example icon for lounge
//       case '24_hour_front_desk':
//         return 'fa-solid fa-clock'; // Example icon for 24-hour front desk
//       case 'laundry':
//         return 'fa-solid fa-tshirt'; // Example icon for laundry service
//       case 'accessibility_mobility':
//         return 'fa-solid fa-wheelchair'; // Example icon for wheelchair access
//       case 'parking':
//         return 'fa-solid fa-parking'; // Example icon for parking
//       case 'gym':
//         return 'fa-solid fa-dumbbell'; // Example icon for gym
//       case 'concierge':
//         return 'fa-solid fa-hands-helping'; // Example icon for concierge
//       case 'restaurant':
//         return 'fa-solid fa-utensils'; // Example icon for restaurant
//       case 'pets_allowed':
//         return 'fa-solid fa-paw'; // Example icon for pets allowed
//       case 'spa':
//         return 'fa-solid fa-spa'; // Example icon for spa
//       case 'business_centre':
//         return 'fa-solid fa-building-columns'; // Example icon for business centre
//       case 'pool':
//         return 'fa-solid fa-person-swimming';
//       default:
//         return 'bi bi-circle-fill';
//     }
//   };

//   mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

//   useEffect(() => {
//     if (hotelDetails.accommodation) {
//       // Initialize the map once the component mounts
//       const map = new mapboxgl.Map({
//         container: mapContainerRef.current, // Reference to the map container
//         style: 'mapbox://styles/mapbox/streets-v11',
//         center: [
//           hotelDetails.accommodation.location.geographic_coordinates.longitude,
//           hotelDetails.accommodation.location.geographic_coordinates.latitude,
//         ],
//         zoom: 12, // Initial zoom level
//         attributionControl: false,
//       });

//       const marker = new mapboxgl.Marker()
//         .setLngLat([
//           hotelDetails.accommodation.location.geographic_coordinates.longitude,
//           hotelDetails.accommodation.location.geographic_coordinates.latitude,
//         ])
//         .addTo(map);

//       // Ensure marker stays in place
//       map.on('zoom', () => {
//         const zoomedLocation = [
//           hotelDetails.accommodation.location.geographic_coordinates.longitude,
//           hotelDetails.accommodation.location.geographic_coordinates.latitude,
//         ];
//         marker.setLngLat(zoomedLocation);
//       });

//       // map.on('zoomend', () => {
//       //   marker.setLngLat([
//       //     hotelDetails.accommodation.location.geographic_coordinates.longitude,
//       //     hotelDetails.accommodation.location.geographic_coordinates.latitude,
//       //   ]);
//       // });
      
//       // map.on('moveend', () => {
//       //   marker.setLngLat([
//       //     hotelDetails.accommodation.location.geographic_coordinates.longitude,
//       //     hotelDetails.accommodation.location.geographic_coordinates.latitude,
//       //   ]);
//       // });

//       // Cleanup on unmount
//       return () => map.remove();
//     }
//   }, [hotelDetails]);

//   return (
//     <>
//       <Navbar />
//       <div className="container pt-4">
//         {hotelDetails.accommodation ? (
//           // hotelDetails.map((hotel) => (
//           <div>
//             <div className="hotel-name">
//               {/* <h3>Grand Canyon Horseshoe Bend</h3> */}
//               <h3>{hotelDetails.accommodation.name || 'No name available'}</h3>
//               <div className='small-info'>
//                 <p> <span><i class="bi bi-star-fill"></i>  {hotelDetails.accommodation.rating || 'No rating available'}</span>
//                   <span className='span'> ({'100'} reviews) </span>
//                   <span className='line-span'> {hotelDetails.accommodation.location.address.city_name || 'City Name not found'}
//                     {/*, {hotelDetails.accommodation.location.address.country_code || 'City Name not found'}*/}</span> </p>
//                 <h6><i class="bi bi-heart"></i> Save </h6>
//               </div>
//             </div>
//             <div className="row image-container">
//               <div className="col-lg-6 col-left">
//                 <div className="detail-img">
//                   {/* <img src="assets-travel/detail.png" alt="" /> */}
//                   <img src={hotelDetails.accommodation.photos[0]?.url || "assets-travel/detail.png"} alt={hotelDetails.accommodation.name || 'Hotel image'} />
//                 </div>
//               </div>
//               <div className="col-lg-6 col-right">
//                 <div className="grid-container">
//                   <div className="grid-item">
//                     {/* <img src="assets-travel/detail1.png" alt="" /> */}
//                     <img src={hotelDetails.accommodation.photos[1]?.url || "assets-travel/detail1.png"} alt={hotelDetails.accommodation.name || 'Hotel image'} />
//                   </div>
//                   <div className="grid-item">
//                     {/* <img src="assets-travel/detail2.png" alt="" /> */}
//                     <img src={hotelDetails.accommodation.photos[2]?.url || "assets-travel/detail2.png"} alt={hotelDetails.accommodation.name || 'Hotel image'} />
//                   </div>
//                   <div className="grid-item">
//                     {/* <img src="assets-travel/detail3.png" alt="" /> */}
//                     <img src={hotelDetails.accommodation.photos[3]?.url || "assets-travel/detail3.png"} alt={hotelDetails.accommodation.name || 'Hotel image'} />
//                   </div>
//                   <div className="grid-item">
//                     {/* <img src="assets-travel/detail4.png" alt="" /> */}
//                     <img src={hotelDetails.accommodation.photos[4]?.url || "assets-travel/detail4.png"} alt={hotelDetails.accommodation.name || 'Hotel image'} />
//                     <div className="button-over-image">
//                       <button>
//                         <i className="bi bi-camera-fill"></i> See all photos
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="row pt-3">
//               <div className="col-lg-8">
//                 <div className="hotel-detail-content">
//                   <div className="detail-name">
//                     <h4>
//                       {hotelDetails.accommodation.name || 'No name available'}
//                     </h4>
//                     {/* <p> 2 queen bed - Dedicated bathroom</p> */}
//                   </div>
//                   <div className="short-review-box mt-5">
//                     <div>
//                       <img src="assets-travel/guest-cup.png" width={96} height={84} alt="" />
//                     </div>
//                     <div>
//                       <p>
//                         One of the most loved hotel on <b>Destinique Australia</b> <br /> in{" "}
//                         by our Guests
//                       </p>
//                     </div>
//                     <div className="num-box">
//                       <h6>{hotelDetails.accommodation.ratings?.[0]?.value || '0'}</h6>
//                       <span>
//                         <div className="star-icon">
//                           {[...Array(5)].map((_, i) => (
//                             <i key={i} className={`bi bi-star-fill ${i < (hotelDetails.accommodation?.rating || 0) ? 'text-warning' : ''}`}></i>
//                           ))}
//                         </div>
//                       </span>
//                     </div>
//                     <div className="num-box">
//                       <h6>100</h6>
//                       <span>Reviews</span>
//                     </div>
//                   </div>

//                   <div className="about-hotel pt-5">
//                     <h5>About this place</h5>
//                     {/* <p className="mt-3">Come and stay in this superb Grand Canyon Horseshoe Bend, in the heart of the historic center of Bordeaux.
//                       Spacious and bright, in a real Bordeaux building in exposed stone, you will enjoy all the charms of the city thanks to its ideal location. Close to many shops, bars and restaurants, you can access the apartment by tram A and C and bus routes 27 and 44</p> */}
//                     <p>{hotelDetails.accommodation.description || 'No description available'}</p>
//                   </div>

//                   {/* <div className="amenities pt-4">
//                     <h4>Amenities We offer</h4>
//                     <div className="facility-box">
//                       {hotelDetails.accommodation.amenities.map((amenities) => (
//                       <div className="facility-list">
//                         <h5><i class="bi bi-circle-fill me-2"></i>{amenities.description || 'Not available'}</h5>
//                       </div>
//                       ))}
//                     </div>
//                   </div> */}
//                   <div className="amenities pt-4">
//                     <h4>Amenities We offer</h4>
//                     <div className="facility-box">
//                       {hotelDetails.accommodation.amenities.map((amenity, index) => (
//                         <div key={index} className="facility-list">
//                           <h5>
//                             <i className={`${getIconClass(amenity.type)} me-2`}></i>
//                             {amenity.description || 'Not available'}
//                           </h5>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-4">
//                 <div className="pay-column-card">
//                   <h6>A$ {hotelDetails.accommodation.cheapest_rate_total_amount || 'N/A'} <span>/night</span></h6>

//                   <form className="pay-date-box">
//                     <div className="date-box">
//                       <input type="date" className='form-control' />
//                       <input type="date" className='form-control' />
//                     </div>
//                     <div className="guest-info-box">
//                       <div className="popover-box">
//                         <input
//                           type="text"
//                           className="form-control"
//                           value={guestInfoString}
//                           readOnly
//                           onClick={togglePopover}
//                         />
//                         {showPopover && (
//                           <div className="popover-content p-3 border mt-2">
//                             <div className="form-group d-flex justify-content-between align-items-center">
//                               <label>Adults</label>
//                               <div>
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary btn-sm me-2"
//                                   onClick={() => decreaseCount(setAdults, adults)}
//                                 >
//                                   -
//                                 </button>
//                                 <span>{adults}</span>
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary btn-sm ms-2"
//                                   onClick={() => increaseCount(setAdults, adults)}
//                                 >
//                                   +
//                                 </button>
//                               </div>
//                             </div>
//                             <div className="form-group d-flex justify-content-between align-items-center mt-3">
//                               <label>Infants</label>
//                               <div>
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary btn-sm me-2"
//                                   onClick={() => decreaseCount(setInfants, infants)}
//                                 >
//                                   -
//                                 </button>
//                                 <span>{infants}</span>
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary btn-sm ms-2"
//                                   onClick={() => increaseCount(setInfants, infants)}
//                                 >
//                                   +
//                                 </button>
//                               </div>
//                             </div>
//                             <div className="form-group d-flex justify-content-between align-items-center mt-3">
//                               <label>Children</label>
//                               <div>
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary btn-sm me-2"
//                                   onClick={() => decreaseCount(setChildren, children)}
//                                 >
//                                   -
//                                 </button>
//                                 <span>{children}</span>
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary btn-sm ms-2"
//                                   onClick={() => increaseCount(setChildren, children)}
//                                 >
//                                   +
//                                 </button>
//                               </div>
//                             </div>
//                             <div className="form-group d-flex justify-content-between align-items-center mt-3">
//                               <label>Rooms</label>
//                               <div>
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary btn-sm me-2"
//                                   onClick={() => decreaseCount(setRooms, rooms)}
//                                 >
//                                   -
//                                 </button>
//                                 <span>{rooms}</span>
//                                 <button
//                                   type="button"
//                                   className="btn btn-secondary btn-sm ms-2"
//                                   onClick={() => increaseCount(setRooms, rooms)}
//                                 >
//                                   +
//                                 </button>
//                               </div>
//                             </div>
//                           </div>
//                         )}
//                       </div>
//                     </div>

//                     <div className="amount-info-box mt-3">
//                       {/* <button>Book Now</button> */}
//                       <button onClick={() => handleCheckoutDetails(hotelDetails.id)}>Book Now</button>
//                       <p>You won’t be charged yet</p>
//                     </div>
//                   </form>

//                   <div className="price-detail-box mt-3">
//                     {/* <div className="breakdown-box">
//                       <h5>
//                         Best Price
//                       </h5>
//                       <h6>
//                         A$ {hotelDetails.accommodation.cheapest_rate_total_amount || 'N/A'}
//                       </h6>
//                     </div>
//                     <div className="breakdown-box">
//                       <h5>Cleaning fee</h5>
//                       <h6>$62</h6>
//                     </div>
//                     <div className="breakdown-box">
//                       <h5>Service Fee</h5>
//                       <h6>$83</h6>
//                     </div>
//                     <div className="breakdown-box">
//                       <h5>Occupancy taxes and fees</h5>
//                       <h6>$23 </h6>
//                     </div> */}
//                     <hr />
//                     <div className="total-price breakdown-box">
//                       <h5>Best Price</h5>
//                       <h6>
//                         A$ {hotelDetails.accommodation.cheapest_rate_total_amount || 'N/A'}
//                       </h6>
//                     </div>
//                   </div>

//                 </div>
//               </div>
//             </div>

//             <div className="row selection-row pt-5">
//               <h3>Select your room</h3>
//               <div className="col-lg-6">
//                 <div className="room-select-column">
//                   <div className="radio-select-box pb-2">
//                     <label class="form-check-label" for="flexRadioDefault1">
//                       Deluxe Room
//                     </label>
//                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
//                   </div>

//                   <div className='room-detail-box'>
//                     <div className="inner-img-box">
//                       <img src="assets-travel/deluxe.png" alt="" />
//                     </div>
//                     <div className="amenities pt-4">
//                       <div className="facility-box1">
//                         {/* {hotelDetails.accommodation.amenities.map((amenity, index) => (
//                         <div key={index} className="facility-list">
//                           <h5>
//                             <i className={`${getIconClass(amenity.type)} me-2`}></i>
//                             {amenity.description || 'Not available'}
//                           </h5>
//                         </div>
//                       ))} */}
//                         <div className="facility-list1">
//                           <h5> <i class="fa-solid fa-wifi me-2"></i>  Fast wifi - 100 Mbps</h5>
//                         </div>
//                         <div className="facility-list1">
//                           <h5> <i class="fa-solid fa-person-swimming me-2"></i>  Swimming Pool</h5>
//                         </div>
//                         <div className="facility-list1">
//                           <h5> <i class="fa-solid fa-bed me-2"></i>  Double Bed</h5>
//                         </div>
//                         <div className="facility-list1">
//                           <h5> <i class="fa-solid fa-car me-2"></i> Free Parking</h5>
//                         </div>
//                         <div className="facility-list1">
//                           <h5> <i class="fa-solid fa-smoking me-2"></i>  Smoking room</h5>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="room-select-column ">
//                   <div className="radio-select-box pb-2">
//                     <label class="form-check-label" for="flexRadioDefault1">
//                       Classic Room
//                     </label>
//                     <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
//                   </div>

//                   <div className='room-detail-box'>
//                     <div className="inner-img-box">
//                       <img src="assets-travel/deluxe.png" alt="" />
//                     </div>
//                     <div className="amenities pt-4">
//                       <div className="facility-box1">
//                         <div className="facility-list1">
//                           <h5> <i class="fa-solid fa-wifi me-2"></i>  Fast wifi - 100 Mbps</h5>
//                         </div>
//                         <div className="facility-list1">
//                           <h5> <i class="fa-solid fa-person-swimming me-2"></i>  Swimming Pool</h5>
//                         </div>
//                         <div className="facility-list1">
//                           <h5> <i class="fa-solid fa-bed me-2"></i>  Double Bed</h5>
//                         </div>
//                         <div className="facility-list1">
//                           <h5> <i class="fa-solid fa-car me-2"></i> Free Parking</h5>
//                         </div>
//                         <div className="facility-list1">
//                           <h5> <i class="fa-solid fa-smoking me-2"></i>  Smoking room</h5>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                 </div>
//               </div>
//             </div>

//             <div className="row map-row pt-4">
//               <h3>Where you will be</h3>
//               <div className="col-lg-6">
//                 <div className="map-box">
//                   {/* Interactive Map */}
//                   <div ref={mapContainerRef} style={{ width: '100%', height: '400px' }} allowfullscreen="" loading="lazy" />
//                   {/* <iframe src={mapImageUrl} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
//                   {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12765.887402923154!2d-111.5103628!3d36.8790588!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87346c8d22566b09%3A0x45637089634f1702!2sHorseshoe%20Bend!5e0!3m2!1sen!2sin!4v1723195986846!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
//                 </div>
//               </div>
//               <div className="col-lg-6">
//                 <div className="map-place-detail">
//                   <h5>{hotelDetails.accommodation.name || 'No name available'}</h5><br />
//                   {/* <p className='pt-2'>Come and stay in this superb Grand Canyon Horseshoe Bend, in the heart of the historic center of
//                     Bordeaux.Spacious and bright, in a real Bordeaux building in exposed stone, you will enjoy all the charms of the
//                     city thanks to its ideal location. Close to many shops, bars and restaurants, you can access the apartment by
//                     tram A and C and bus routes 27 and 44</p> */}
//                   <div className="row place-map-info">
//                     <div className="row-lg-6">
//                       <i class="bi bi-geo-alt me-2"></i>
//                       {hotelDetails.accommodation.location.address.line_one || 'No name available'},
//                       {hotelDetails.accommodation.location.address.city_name || 'No name available'},
//                       {hotelDetails.accommodation.location.address.country_code || 'No name available'},
//                       Postal Code:- {hotelDetails.accommodation.location.address.postal_code || 'No name available'}
//                     </div>
//                     <div className="row-lg-6"><br />
//                       <i class="bi bi-telephone-plus me-2"></i>+{hotelDetails.accommodation.phone_number || 'Not available'}
//                     </div>
//                     <div className="row-lg-6"><br />
//                       <i class="bi bi-envelope me-2"></i> {hotelDetails.accommodation.email || 'Not available'}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <br /><br />


//             <div className="hotel-review-container mt-5 pb-5">
//               <h3>Reviews</h3>
//               <div className="row">
//                 <div className="col-lg-4">
//                   <div className="review-column">
//                     <div className="review-header">
//                       <img className="review-avatar" src="assets-travel/review.png" width={40} height={40} alt="" />
//                       <div className="review-details">
//                         <h6 className="review-name">Akash Rawat</h6>
//                         <p className="review-date">August 2, 2024</p>
//                       </div>
//                     </div>
//                     <div className="review-rating">

//                       <span> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>  </span>

//                     </div>
//                     <p className="review-text">
//                       Great hotel, very clean and friendly staff. Would
//                       definitely come back!
//                     </p>
//                   </div>
//                 </div>
//                 <div className="col-lg-4">
//                   <div className="review-column">
//                     <div className="review-header">
//                       <img className="review-avatar" src="assets-travel/review.png" width={40} height={40} alt="" />
//                       <div className="review-details">
//                         <h6 className="review-name">Akash Rawat</h6>
//                         <p className="review-date">August 2, 2024</p>
//                       </div>
//                     </div>
//                     <div className="review-rating">

//                       <span> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>  </span>

//                     </div>
//                     <p className="review-text">
//                       Great hotel, very clean and friendly staff. Would
//                       definitely come back!
//                     </p>
//                   </div>
//                 </div>
//                 <div className="col-lg-4">
//                   <div className="review-column">
//                     <div className="review-header">
//                       <img className="review-avatar" src="assets-travel/review.png" width={40} height={40} alt="" />
//                       <div className="review-details">
//                         <h6 className="review-name">Akash Rawat</h6>
//                         <p className="review-date">August 2, 2024</p>
//                       </div>
//                     </div>
//                     <div className="review-rating">

//                       <span> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i> <i class="bi bi-star-fill"></i>  </span>

//                     </div>
//                     <p className="review-text">
//                       Great hotel, very clean and friendly staff. Would
//                       definitely come back!
//                     </p>
//                   </div>
//                 </div>

//               </div>
//             </div>
//           </div>
//         ) : (
//           <p>No hotels found.</p>
//         )}

//       </div>
//       <Footer />
//     </>
//   )
// }

// export default Hoteldetail