// import React, { useState } from 'react';
// import Navbar from '../components/Navbar';
// import ReactSlider from 'react-slider';

// const HotelListing = () => {

//   const [priceRange, setPriceRange] = useState([500, 10000]);


//   const handlePriceChange = (values) => {
//     setPriceRange(values);
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="container pt-4 mt-2">
//         <div className="list-heading">
//           <div className="sort-content">
//             <h3>Best Hotels to stay in Sydney, Australia</h3>
//             <h6>200+ Hotels Found in Sydney, Australia</h6>
//           </div>
//           <div className="sort-select">
//             <h6>Sort by:</h6>
//             <div>
//               <select className="form-select" aria-label="Default select example">
//                 <option defaultValue="Popularity">Popularity</option>
//                 <option value="1">Rating</option>
//                 <option value="2">Star</option>
//                 <option value="3">View</option>
//               </select>
//             </div>
//           </div>
//         </div>
//         <div className="row pt-4">
//           <div className="col-lg-3">
//             <div className="filter-column">
//               <div className="availability-filter">
//                 <h5>Availability</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <div className="mb-3">
//                     <label htmlFor="">Destination</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Sydney, Australia"
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">From</label>
//                     <input type="date" className="form-control" />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">To</label>
//                     <input type="date" className="form-control" />
//                   </div>
//                   <div className="avail-btn">
//                     <button>Check Availability</button>
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Price</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <label>
//                     <span>AUD {priceRange[0]}</span> - <span>AUD {priceRange[1]}</span>
//                   </label>
//                   <div className="range-input">
//                     <ReactSlider
//                       className="horizontal-slider"
//                       thumbClassName="thumb"
//                       trackClassName="track"
//                       min={500}
//                       max={10000}
//                       value={priceRange}
//                       onChange={handlePriceChange}
//                       minDistance={100}
//                     />
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Property Type</h5>
//                 <hr />
//                 <form className="filter-form">
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     Apartments
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//    Hotels
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     Villas
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     Hostels
//   </label>
// </div>
// <span>(123)</span>
// </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Property Ratings</h5>
//                 <hr />
//                 <form className="filter-form">
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     2 Star
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//    3 Star
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     4 Star
//   </label>
// </div>
// <span>(123)</span>
// </div>
// <div className="check-filter">
// <div class="form-check">
//   <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
//   <label class="form-check-label" for="flexCheckDefault">
//     5 Star
//   </label>
// </div>
// <span>(123)</span>
// </div>
//                 </form>
//               </div>

//             </div>
//           </div>

// <div className="col-lg-9 pt-4">
//   <div className="row hotel-list-card">
//     <div className="col-lg-4 hotel-img-box">
//       <img src="assets-travel/listing-hotel.png" alt="" />
//     </div>
//     <div className="col-lg-8 hotel-list-content">
//      <div className="content-heading">
//       <div>
//       <p>Entire home in Bordeaux</p>
//       <h6>Bordeaux Getaway</h6>
//       </div>
//       <div>
//     <i class="bi bi-heart"></i>
//       </div>
//      </div>
//      <div className="amenities-list">
//       <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//       <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
//      </div>

// <div className="rating-book">
// <div className="rating-review-list">
//       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
//      </div>
//      <div className='book-list'>
//       <p>1 night, 2adults</p>
//       <h5>$125</h5>
//       <button>View Details</button>
//      </div>
// </div>
//     </div>
//   </div>

// <hr />
// <div className="row hotel-list-card">
//     <div className="col-lg-4 hotel-img-box">
//       <img src="assets-travel/listing-hotel1.png" alt="" />
//     </div>
//     <div className="col-lg-8 hotel-list-content">
//      <div className="content-heading">
//       <div>
//       <p>Entire home in Bordeaux</p>
//       <h6>Bordeaux Getaway</h6>
//       </div>
//       <div>
//     <i class="bi bi-heart"></i>
//       </div>
//      </div>
//      <div className="amenities-list">
//       <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//       <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
//      </div>

// <div className="rating-book">
// <div className="rating-review-list">
//       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
//      </div>
//      <div className='book-list'>
//       <p>1 night, 2adults</p>
//       <h5>$125</h5>
//       <button>View Details</button>
//      </div>
// </div>
//     </div>
//   </div>

// <hr />

// <div className="row hotel-list-card">
//     <div className="col-lg-4 hotel-img-box">
//       <img src="assets-travel/listing-hotel2.png" alt="" />
//     </div>
//     <div className="col-lg-8 hotel-list-content">
//      <div className="content-heading">
//       <div>
//       <p>Entire home in Bordeaux</p>
//       <h6>Bordeaux Getaway</h6>
//       </div>
//       <div>
//     <i class="bi bi-heart"></i>
//       </div>
//      </div>
//      <div className="amenities-list">
//       <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//       <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
//      </div>

// <div className="rating-book">
// <div className="rating-review-list">
//       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
//      </div>
//      <div className='book-list'>
//       <p>1 night, 2adults</p>
//       <h5>$125</h5>
//       <button>View Details</button>
//      </div>
// </div>
//     </div>
//   </div>

// <hr />

// <div className="row hotel-list-card">
//     <div className="col-lg-4 hotel-img-box">
//       <img src="assets-travel/listing-hotel1.png" alt="" />
//     </div>
//     <div className="col-lg-8 hotel-list-content">
//      <div className="content-heading">
//       <div>
//       <p>Entire home in Bordeaux</p>
//       <h6>Bordeaux Getaway</h6>
//       </div>
//       <div>
//     <i class="bi bi-heart"></i>
//       </div>
//      </div>
//      <div className="amenities-list">
//       <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//       <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
//      </div>

// <div className="rating-book">
// <div className="rating-review-list">
//       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
//      </div>
//      <div className='book-list'>
//       <p>1 night, 2adults</p>
//       <h5>$125</h5>
//       <button>View Details</button>
//      </div>
// </div>
//     </div>
//   </div>

// <hr />

// <div className="row hotel-list-card">
//     <div className="col-lg-4 hotel-img-box">
//       <img src="assets-travel/listing-hotel1.png" alt="" />
//     </div>
//     <div className="col-lg-8 hotel-list-content">
//      <div className="content-heading">
//       <div>
//       <p>Entire home in Bordeaux</p>
//       <h6>Bordeaux Getaway</h6>
//       </div>
//       <div>
//     <i class="bi bi-heart"></i>
//       </div>
//      </div>
//      <div className="amenities-list">
//       <p><span>4-6 guests</span> · <span>Entire Home</span> · <span>5 beds</span> · <span>3 bath</span></p>
//       <p><span>Wifi</span> · <span>Kitchen</span> · <span>Free Parking</span></p>
//      </div>

// <div className="rating-book">
// <div className="rating-review-list">
//       <h6>5.0 <span><i class="bi bi-star-fill"></i></span> <small>(318 reviews)</small> </h6>
//      </div>
//      <div className='book-list'>
//       <p>1 night, 2adults</p>
//       <h5>$125</h5>
//       <button>View Details</button>
//      </div>
// </div>
//     </div>
//   </div>

// <hr />

// </div>

//         </div>
//       </div>
//     </>
//   );
// };

// export default HotelListing;


// import React, { useState } from 'react';
// import Navbar from '../components/Navbar';
// import Footer from '../components/Footer';
// import ReactSlider from 'react-slider';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useAuth } from "../AuthContext";
// import axios from 'axios';
// import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

// const HotelListing = () => {
//   const [priceRange, setPriceRange] = useState([0, 10000]);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const searchResults = location.state?.searchResults || [];
//   const [cityName, setCityName] = useState("");
//   const [checkInDate, setCheckInDate] = useState("");
//   const [checkOutDate, setCheckOutDate] = useState("");
//   const [guests, setGuests] = useState(1);
//   const [rooms, setRooms] = useState(1);
//   const [adults, setAdults] = useState(1);
//   const [children, setChildren] = useState(0);
//   const [loading, setLoading] = useState(false);
//   // const [propertyTypes, setPropertyTypes] = useState({
//   //   apartments: false,
//   //   hotels: false,
//   //   villas: false,
//   //   hostels: false,
//   // });
//   const [ratings, setRatings] = useState({
//     2: false,
//     3: false,
//     4: false,
//     5: false,
//   });

//   const { apipath } = useAuth();

//   const address = searchResults[0]?.accommodation?.location?.address || "Unknown City";

//   const handlePriceChange = (values) => {
//     setPriceRange(values);
//   };

//   // const handlePropertyTypeChange = (type) => {
//   //   setPropertyTypes(prev => ({ ...prev, [type]: !prev[type] }));
//   // };

//   const handleRatingChange = (rating) => {
//     setRatings(prev => ({ ...prev, [rating]: !prev[rating] }));
//   };

//   // Calculate counts for each star rating
//   const starCounts = { 2: 0, 3: 0, 4: 0, 5: 0 };
//   searchResults.forEach(hotel => {
//     const rating = hotel.accommodation.rating;
//     if (rating >= 2 && rating <= 5) {
//       starCounts[rating]++;
//     }
//   });

//   // Filter function
//   const filteredResults = searchResults.filter(hotel => {
//     const hotelPrice = hotel.accommodation.cheapest_rate_total_amount || 0;
//     const hotelRating = hotel.accommodation.rating || 0;
//     // const hotelType = hotel.accommodation.property_type || '';

//     const withinPriceRange = hotelPrice >= priceRange[0] && hotelPrice <= priceRange[1];
//     // const matchesPropertyType = propertyTypes[hotelType] || Object.values(propertyTypes).every(val => !val);
//     const matchesRating = ratings[hotelRating] || Object.values(ratings).every(val => !val);

//     // return withinPriceRange && matchesPropertyType && matchesRating;
//     return withinPriceRange && matchesRating;
//   });

//   const handleViewDetails = (hotelId) => {
//     navigate(`/hotel-details`, { state: { hotel: searchResults.find(hotel => hotel.id === hotelId) } });
//   };



//   const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

//   if (!mapboxToken) {
//     console.error('Mapbox access token is not set');
//     return <div>Error: Mapbox access token is not set.</div>;
//   }

//   const mapboxClient = mbxGeocoding({ accessToken: mapboxToken });

//   const handlehotelSearch = async () => {
//     setLoading(true);
//     const token = localStorage.getItem('token');
//     if (token) {
//       try {
//         const response = await mapboxClient
//           .forwardGeocode({
//             query: cityName,
//             limit: 1,
//           })
//           .send();

//         const [longitude, latitude] = response.body.features[0].center;

//         // const guestType = 'adult';

//         const formData = new FormData();
//         formData.append('longitude', longitude);
//         formData.append('latitude', latitude);
//         formData.append('check_in_date', checkInDate);
//         formData.append('check_out_date', checkOutDate);
//         formData.append('guests', guests);
//         formData.append('rooms', rooms);

//         const hotelResponse = await axios.post(`${apipath}/search-hotels/`, formData);

//         const searchResults = hotelResponse.data.data.results;

//         navigate('/hotellisting', { state: { searchResults } });
//       } catch (error) {
//         console.error('Error during hotel search:', error);
//       }finally {
//         setLoading(false);
//       }
//     } else {
//       // localStorage.setItem('redirectAfterLogin', '/hotelcheckout', { state: { hotel: hotelDetails } });
//       navigate('/login');
//     }
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="container pt-4 mt-2">
//         <div className="list-heading">
//           <div className="sort-content">
//             <h3>Best Hotels to stay search results on Destinique Australia</h3>
//             <h6>{filteredResults.length} Hotels Found in {address.city_name}</h6>
//           </div>
//           <div className="sort-select">
//             <h6>Sort by:</h6>
//             <div>
//               <select className="form-select" aria-label="Default select example">
//                 <option defaultValue="Popularity">Popularity</option>
//                 <option value="1">Rating</option>
//                 <option value="2">Star</option>
//                 <option value="3">View</option>
//               </select>
//             </div>
//           </div>
//         </div>
//         <div className="row pt-4">
//           <div className="col-lg-3">
//             <div className="filter-column">
//               <div className="availability-filter">
//                 <h5>Availability</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <div className="mb-3">
//                     <label htmlFor="">Destination</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       // placeholder={address.city_name || ''}
//                       defaultValue={address.city_name || ''}
//                       // value={cityName}
//                       onChange={(e) => setCityName(e.target.value)}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">From</label>
//                     <input
//                       type="date"
//                       className="form-control"
//                       // defaultValue={filteredResults[0].check_in_date || ''}
//                       value={checkInDate}
//                       onChange={(e) => setCheckInDate(e.target.value)}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="">To</label>
//                     <input
//                       type="date"
//                       className="form-control"
//                       // defaultValue={filteredResults[0].check_out_date || ''}
//                       value={checkOutDate}
//                       onChange={(e) => setCheckOutDate(e.target.value)}
//                     />
//                   </div>
//                   <div className="avail-btn">
//                     <button onClick={handlehotelSearch} disabled={loading}>
//                       {loading ? "Checking..." : "Check Availability"}
//                     </button>
//                   </div>
//                 </form>
//               </div>

//               <div className="availability-filter">
//                 <h5>Price</h5>
//                 <hr />
//                 <form className="filter-form">
//                   <label>
//                     <span>A$ {priceRange[0]}</span> - <span>A$ {priceRange[1]}</span>
//                   </label>
//                   <div className="range-input">
//                     <ReactSlider
//                       className="horizontal-slider"
//                       thumbClassName="thumb"
//                       trackClassName="track"
//                       min={0}
//                       max={10000}
//                       value={priceRange}
//                       onChange={handlePriceChange}
//                       minDistance={100}
//                     />
//                   </div>
//                 </form>
//               </div>

//               {/* <div className="availability-filter">
//                 <h5>Property Type</h5>
//                 <hr />
//                 <form className="filter-form">
//                   {Object.keys(propertyTypes).map(type => (
//                     <div className="check-filter" key={type}>
//                       <div className="form-check">
//                         <input
//                           className="form-check-input"
//                           type="checkbox"
//                           checked={propertyTypes[type]}
//                           onChange={() => handlePropertyTypeChange(type)}
//                         />
//                         <label className="form-check-label">
//                           {type.charAt(0).toUpperCase() + type.slice(1)}
//                         </label>
//                       </div>
//                       <span>(123)</span>
//                     </div>
//                   ))}
//                 </form>
//               </div> */}

//               <div className="availability-filter">
//                 <h5>Property Ratings</h5>
//                 <hr />
//                 <form className="filter-form">
//                   {Object.keys(starCounts).map(rating => (
//                     <div className="check-filter" key={rating}>
//                       <div className="form-check">
//                         <input
//                           className="form-check-input"
//                           type="checkbox"
//                           checked={ratings[rating]}
//                           onChange={() => handleRatingChange(rating)}
//                         />
//                         <label className="form-check-label">
//                           {rating} Star
//                         </label>
//                       </div>
//                       <span>{starCounts[rating]}</span>
//                     </div>
//                   ))}
//                 </form>
//               </div>

//             </div>
//           </div>

//           <div className="col-lg-9 pt-4">
//             {filteredResults.length > 0 ? (
//               filteredResults.map((hotel) => (
//                 <div key={hotel.id}>
//                   <div className="row hotel-list-card">
//                     <div className="col-lg-4 hotel-img-box">
//                       <img src={hotel.accommodation.photos[0]?.url || "default-hotel-image.jpg"} alt={hotel.accommodation.name || 'Hotel image'} />
//                     </div>
//                     <div className="col-lg-8 hotel-list-content">
//                       <div className="content-heading">
//                         <div>
//                           <h6>{hotel.accommodation.name || 'No name available'}</h6>
//                         </div>
//                         <div>
//                           <i className="bi bi-heart"></i>
//                         </div>
//                       </div>
//                       <div className="ai-icon">
//                         {hotel.accommodation.rating === 5 && (
//                           <p> <img src="" alt="" className='me-2' /> AI Recommended</p>
//                         )}
//                       </div>
//                       <div className="amenities-list">
//                         {/* <p>
//                           Rooms - <span>{hotel.rooms || 'No info'}</span>
//                         </p> */}
//                         <p>
//                           {Array.isArray(hotel.accommodation.amenities)
//                             ? hotel.accommodation.amenities.slice(0, 5).map(amenity => amenity.description).join(' · ')
//                             : 'No amenities listed'}{' '}{' '}...more
//                         </p>
//                       </div>

//                       <div className="rating-book">
//                         <div className="rating-review-list">
//                           <h6>
//                             {hotel.accommodation.rating || 'No score'}
//                             <span><i className="bi bi-star-fill"></i></span>
//                             <small>({hotel.accommodation.ratings?.[0]?.value || '0'} reviews)</small>
//                           </h6>
//                         </div>
//                         <div className='book-list'>
//                           <p>{hotel.adults || 'No info'} Guests</p>
//                           <h5>A$ {hotel.accommodation.cheapest_rate_total_amount || 'N/A'}</h5>
//                           <button onClick={() => handleViewDetails(hotel.id)}>View Details</button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <hr />
//                 </div>
//               ))
//             ) : (
//               <p>No hotels found.</p>
//             )}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default HotelListing;


import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ReactSlider from 'react-slider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../AuthContext";

const HotelListing = () => {
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const location = useLocation();
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const searchResults = location.state?.searchResults || [];
  const [searchQuery, setSearchQuery] = useState({
    cityName: location.state?.searchQuery?.cityName || '',
    checkInDate: location.state?.searchQuery?.checkInDate || '',
    checkOutDate: location.state?.searchQuery?.checkOutDate || ''
  });
  const [loading, setLoading] = useState(false);
  // const [propertyTypes, setPropertyTypes] = useState({
  //   apartments: false,
  //   hotels: false,
  //   villas: false,
  //   hostels: false,
  // });
  const [ratings, setRatings] = useState({
    2: false,
    3: false,
    4: false,
    5: false,
  });

  const address = searchResults[0]?.accommodation?.location?.address || "Unknown City";

  const handlePriceChange = (values) => {
    setPriceRange(values);
  };

  // const handlePropertyTypeChange = (type) => {
  //   setPropertyTypes(prev => ({ ...prev, [type]: !prev[type] }));
  // };

  const handleRatingChange = (rating) => {
    setRatings(prev => ({ ...prev, [rating]: !prev[rating] }));
  };

  // Calculate counts for each star rating
  const starCounts = { 2: 0, 3: 0, 4: 0, 5: 0 };
  searchResults.forEach(hotel => {
    const rating = hotel.accommodation.rating;
    if (rating >= 2 && rating <= 5) {
      starCounts[rating]++;
    }
  });

  // Filter function
  const filteredResults = searchResults.filter(hotel => {
    const hotelPrice = hotel.accommodation.cheapest_rate_total_amount || 0;
    const hotelRating = hotel.accommodation.rating || 0;
    // const hotelType = hotel.accommodation.property_type || '';

    const withinPriceRange = hotelPrice >= priceRange[0] && hotelPrice <= priceRange[1];
    // const matchesPropertyType = propertyTypes[hotelType] || Object.values(propertyTypes).every(val => !val);
    const matchesRating = ratings[hotelRating] || Object.values(ratings).every(val => !val);

    // return withinPriceRange && matchesPropertyType && matchesRating;
    return withinPriceRange && matchesRating;
  });

  const handleInputChange = (e) => {
    setSearchQuery({ ...searchQuery, [e.target.name]: e.target.value });
  };

  const fetchSearchResults = async (query) => {
    const formData = new FormData();
    formData.append('city_name', query.cityName);
    formData.append('check_in_date', query.checkInDate);
    formData.append('check_out_date', query.checkOutDate);

    const response = await fetch(`${apipath}/search-hotels/`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to fetch search results');
    }

    const data = await response.json();
    return data.data.results; // Adjust this according to your API response structure
  };

  const handleCheckAvailability = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Replace with the actual API call to fetch search results based on the searchQuery
      const updatedSearchResults = await fetchSearchResults(searchQuery);
      navigate(`/hotellisting`, {
        state: {
          searchResults: updatedSearchResults,
          searchQuery: searchQuery
        }
      });
    } catch (error) {
      console.error("Error fetching updated search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (hotelId) => {
    navigate(`/hotel-details`, {
      state: {
        hotel: searchResults.find(hotel => hotel.id === hotelId),
        searchQuery: searchQuery
      }
    });
  };

  return (
    <>
      <Navbar />
      <div className="container pt-4 mt-2">
        <div className="list-heading">
          <div className="sort-content">
            <h3>Best Hotels to stay search results on Destinique International</h3>
            <h6>{filteredResults.length} Hotels Found in {address.city_name}</h6>
          </div>
          <div className="sort-select">
            <h6>Sort by:</h6>
            <div>
              <select className="form-select" aria-label="Default select example">
                <option defaultValue="Popularity">Popularity</option>
                <option value="1">Rating</option>
                <option value="2">Star</option>
                <option value="3">View</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row pt-4">
          <div className="col-lg-3">
            <div className="filter-column">
              <div className="availability-filter">
                <h5>Availability</h5>
                <hr />
                <form className="filter-form" onSubmit={handleCheckAvailability}>
                  <div className="mb-3">
                    <label htmlFor="">Destination</label>
                    <input
                      type="text"
                      className="form-control"
                      name="cityName"
                      value={searchQuery.cityName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="">From</label>
                    <input
                      type="date"
                      className="form-control"
                      name="checkInDate"
                      value={searchQuery.checkInDate}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="">To</label>
                    <input
                      type="date"
                      className="form-control"
                      name="checkOutDate"
                      value={searchQuery.checkOutDate}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="avail-btn">
                    <button type="submit" disabled={loading}>
                      {loading ? "Checking..." : "Check Availability"}
                    </button>
                  </div>
                </form>
              </div>

              <div className="availability-filter">
                <h5>Price</h5>
                <hr />
                <form className="filter-form">
                  <label>
                    <span>A$ {priceRange[0]}</span> - <span>A$ {priceRange[1]}</span>
                  </label>
                  <div className="range-input">
                    <ReactSlider
                      className="horizontal-slider"
                      thumbClassName="thumb"
                      trackClassName="track"
                      min={0}
                      max={10000}
                      value={priceRange}
                      onChange={handlePriceChange}
                      minDistance={100}
                    />
                  </div>
                </form>
              </div>

              {/* <div className="availability-filter">
                <h5>Property Type</h5>
                <hr />
                <form className="filter-form">
                  {Object.keys(propertyTypes).map(type => (
                    <div className="check-filter" key={type}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={propertyTypes[type]}
                          onChange={() => handlePropertyTypeChange(type)}
                        />
                        <label className="form-check-label">
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </label>
                      </div>
                      <span>(123)</span>
                    </div>
                  ))}
                </form>
              </div> */}

              <div className="availability-filter">
                <h5>Property Ratings</h5>
                <hr />
                <form className="filter-form">
                  {Object.keys(starCounts).map(rating => (
                    <div className="check-filter" key={rating}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={ratings[rating]}
                          onChange={() => handleRatingChange(rating)}
                        />
                        <label className="form-check-label">
                          {rating} Star
                        </label>
                      </div>
                      <span>{starCounts[rating]}</span>
                    </div>
                  ))}
                </form>
              </div>

            </div>
          </div>

          <div className="col-lg-9 pt-4">
            {filteredResults.length > 0 ? (
              filteredResults.map((hotel) => (
                <div key={hotel.id}>
                  <div className="row hotel-list-card">
                    <div className="col-lg-4 hotel-img-box">
                      <img src={hotel.accommodation.photos[0]?.url || "default-hotel-image.jpg"} alt={hotel.accommodation.name || 'Hotel image'} />
                    </div>
                    <div className="col-lg-8 hotel-list-content">
                      <div className="content-heading">
                        <div>
                          <h6>{hotel.accommodation.name || 'No name available'}</h6>
                        </div>
                        <div>
                          <i className="bi bi-heart"></i>
                        </div>
                      </div>
                      <div className="ai-icon">
                        {hotel.accommodation.rating === 5 && (
                          <p> <img src="" alt="" className='me-2' /> AI Recommended</p>
                        )}
                      </div>
                      <div className="amenities-list">
                        {/* <p>
                          Rooms - <span>{hotel.rooms || 'No info'}</span>
                        </p> */}
                        <p>
                          {Array.isArray(hotel.accommodation.amenities)
                            ? hotel.accommodation.amenities.slice(0, 5).map(amenity => amenity.description).join(' · ')
                            : 'No amenities listed'}{' '}{' '}...more
                        </p>
                      </div>

                      <div className="rating-book">
                        <div className="rating-review-list">
                          <h6>
                            {hotel.accommodation.rating || 'No score'}
                            <span><i className="bi bi-star-fill"></i></span>
                            <small>({hotel.accommodation.ratings?.[0]?.value || '0'} reviews)</small>
                          </h6>
                        </div>
                        <div className='book-list'>
                          <p>{hotel.adults || 'No info'} Guests</p>
                          <h5>A$ {hotel.cheapest_rate_total_amount || 'N/A'}</h5>
                          <button onClick={() => handleViewDetails(hotel.id)}>View Details</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))
            ) : (
              <p>No hotels found.</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HotelListing;
