import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const Navbar = () => {
  const location = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-black sticky-top">
      <div className="container nav-fluid">
        <a className="navbar-brand" href="/">
          <img src="assets-travel/logo.png" alt="Logo" width={80} height={73} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/")}`} to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/about")}`} to="/about">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/support")}`} to="/support">
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/service")}`} to="/service">
                Services
              </Link>
            </li>
          </ul>
          <div className="d-flex nav-buttons">
            {username ? (
              <div className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  id="navbarDarkDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="navbar-text me-3">Hello, {username}</span>
                </Link>
                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                  <li>
                    <Link className="dropdown-item" to="/book">My Bookings</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/wishlist">Wishlist</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">My Profile</Link>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>Logout</button>
                  </li>
                </ul>
              </div>
            ) : (
              <>
                <Link to="/register">
                  <button className="first-btn me-3" type="button">
                    Register
                  </button>
                </Link>
                <Link to="/login">
                  <button className="second-btn me-3" type="button">
                    Login
                  </button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
