import React from 'react';

import { Carousel } from "react-bootstrap";
import ExampleCarouselImage from "../components/ExampleCarouselImage";

    
const Test = () => {
  

  return (
    <>
     

    <Carousel interval={null}>
    <Carousel.Item>
          <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
            <video
              src="assets-travel/banner-bg.mp4"
              autoPlay
              muted
              loop
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'cover',
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex:99,
              }}
            >
              <ExampleCarouselImage />
            </div>
          </div>
          <Carousel.Caption>
            {/* Your caption content */}
          </Carousel.Caption>
        </Carousel.Item>
    </Carousel>


     
    </>
  );
};

export default Test;
