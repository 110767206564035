import React from 'react';
import { FaDollarSign } from 'react-icons/fa'; // Using react-icons for icons

const Step4 = ({ formData, setFormData, prevStep, nextStep }) => {
  const handleBudgetSelect = (budget) => {
    setFormData({ ...formData, budget });
  };

  return (
      <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-lg-6'>
          <div className="form-step">
            <h2>
              Step 4 <i className="bi bi-arrow-right-short"></i> What is your budget range?
            </h2>
            <div className="budget-options">
              <div
                className={`budget-card ${formData.budget === 'low' ? 'selected' : ''}`}
                onClick={() => handleBudgetSelect('low')}
              >
                <FaDollarSign className="budget-icon" />
                <p className="budget-text">Low ($)</p>
              </div>
              <div
                className={`budget-card ${formData.budget === 'medium' ? 'selected' : ''}`}
                onClick={() => handleBudgetSelect('medium')}
              >
                <FaDollarSign className="budget-icon" />
                <p className="budget-text">Medium ($$)</p>
              </div>
              <div
                className={`budget-card ${formData.budget === 'high' ? 'selected' : ''}`}
                onClick={() => handleBudgetSelect('high')}
              >
                <FaDollarSign className="budget-icon" />
                <p className="budget-text">High ($$$)</p>
              </div>
            </div>
            <div className="ai-button">
              <button className='pre-button' onClick={prevStep}>Previous</button>
              <button onClick={nextStep}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4;
