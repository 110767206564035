import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';

const Step1 = ({ formData, setFormData, nextStep }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 ">
          <div className="form-step p-4 rounded shadow-sm">
            <h2 className="mb-4">Step 1 <i className="bi bi-arrow-right-short"></i> Tell us about yourself</h2>

            <Form.Group className="mb-5">
              <Form.Label className="fw-bold">Your Age: {formData.age || "Select Age"}</Form.Label>
              <div className="custom-range-wrapper">
                <Form.Range 
                  min={18}
                  max={100}
                  name="age"
                  value={formData.age || 18}
                  onChange={handleChange}
                  className="custom-range"
                />
                <div className="range-indicator">{formData.age || 18}</div>
              </div>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="fw-bold">Gender</Form.Label>
              <div className="d-flex justify-content-between">
                <div 
                  className={`gender-card ${formData.gender === 'Male' ? "selected" : ""}`}
                  onClick={() => setFormData({ ...formData, gender: 'Male' })}
                >
                  <img 
                    src="assets-travel/avatar.jpg" 
                    alt="Male" 
                    className="gender-image" 
                  />
                  {formData.gender === 'Male' && (
                    <FaCheckCircle className="gender-check" />
                  )}
                  <div className="gender-label">Male</div>
                </div>
                <div 
                  className={`gender-card ${formData.gender === 'Female' ? "selected" : ""}`}
                  onClick={() => setFormData({ ...formData, gender: 'Female' })}
                >
                  <img 
                    src="assets-travel/avatar1.jpg" 
                    alt="Female" 
                    className="gender-image" 
                  />
                  {formData.gender === 'Female' && (
                    <FaCheckCircle className="gender-check" />
                  )}
                  <div className="gender-label">Female</div>
                </div>
              </div>
            </Form.Group>

            <Button className="nex-button" onClick={nextStep}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
