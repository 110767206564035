// import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Navbar from '../components/Navbar';
// import Footer from '../components/Footer';

// const Mybookings = () => {
//   const [activeTab, setActiveTab] = useState('hotel');

//   const hotelBookings = [
//     { id: 1, name: 'The Grand Hotel', date: '2024-08-01', price: '$300', status: 'Confirmed', guests: 2, image: 'assets-travel/detail4.png' },
//     { id: 2, name: 'Ocean View Resort', date: '2024-07-20', price: '$450', status: 'Cancelled', guests: 4, image: 'assets-travel/detail4.png' },
//   ];

//   const flightBookings = [
//     { id: 1, name: 'Flight 1234', from: 'New York', to: 'London', date: '2024-08-15', price: '$500', status: 'Confirmed', passengers: 1, airline: 'Delta Airlines', cabinClass: 'Economy', image: 'assets-travel/offer.png' },
//     { id: 2, name: 'Flight 5678', from: 'Paris', to: 'Tokyo', date: '2024-07-10', price: '$200', status: 'Completed', passengers: 3, airline: 'Air France', cabinClass: 'Business', image: 'assets-travel/offer.png' },
//   ];

//   const renderBookings = (bookings, type) => {
//     return bookings.map((booking) => (
//       <div key={booking.id} className="col-md-6 mb-4">
//         <div className="card booking-card shadow-sm">
//           <div className="row g-0">
//             <div className="col-md-3">
//               <img src={booking.image} alt={booking.name} className="img-fluid rounded-start" />
//             </div>
//             <div className="col-md-9">
//               <div className="card-body">
//                 <h5 className="card-title">{booking.name}</h5>
//                 <div className="row">
//                   <div className="col-6">
//                     <p className="card-text">
//                       <strong>Date:</strong> {booking.date}
//                     </p>
//                     <p className="card-text">
//                       <strong>Price:</strong> {booking.price}
//                     </p>
//                     {type === 'flights' && (
//                       <>
//                         <p className="card-text">
//                           <strong>Airline:</strong> {booking.airline}
//                         </p>
//                         <p className="card-text">
//                           <strong>Cabin Class:</strong> {booking.cabinClass}
//                         </p>
//                       </>
//                     )}
//                   </div>
//                   <div className="col-6">
//                     <p className="card-text">
//                       <strong>{type === 'hotel' ? 'Guests' : 'Passengers'}:</strong> {type === 'hotel' ? booking.guests : booking.passengers}
//                     </p>
//                     {type === 'flights' && (
//                       <p className="card-text">
//                         <strong>Route:</strong> {booking.from} to {booking.to}
//                       </p>
//                     )}
//                     <p className={`card-text status-text ${booking.status.toLowerCase()}`}>
//                       <strong>Status:</strong> {booking.status}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     ));
//   };

//   return (
//     <>
//       <Navbar />
//       <div className="container booking-box mt-5">
//         <div className="tab-buttons d-flex mb-4">
//           <button
//             className={`tab-button ${activeTab === 'hotel' ? 'active' : ''}`}
//             onClick={() => setActiveTab('hotel')}
//           >
//             Hotel
//           </button>
//           <button
//             className={`tab-button ${activeTab === 'flights' ? 'active' : ''}`}
//             onClick={() => setActiveTab('flights')}
//           >
//             Flights
//           </button>
//         </div>

//         <div className="row">
//           {activeTab === 'hotel' && renderBookings(hotelBookings, 'hotel')}
//           {activeTab === 'flights' && renderBookings(flightBookings, 'flights')}
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Mybookings;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useAuth } from "../AuthContext";

const Mybookings = () => {
  const { apipath } = useAuth();
  const [activeTab, setActiveTab] = useState('hotel');
  const [hotelBookings, setHotelBookings] = useState([]);
  // const [flightBookings, setFlightBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve the token from localStorage

        const hotelResponse = await axios.get(`${apipath}/hotelbookdetails/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setHotelBookings(hotelResponse.data.hotelBookings);

        // Uncomment and modify this if you have a separate endpoint for flight bookings
        // const flightResponse = await axios.get('/api/flight-bookings/', {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // });
        // setFlightBookings(flightResponse.data.flightBookings);

        setLoading(false);
      } catch (err) {
        setError('Failed to fetch bookings');
        setLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const flightBookings = [
    { id: 1, name: 'Flight 1234', from: 'New York', to: 'London', date: '2024-08-15', price: '$500', status: 'Confirmed', passengers: 1, airline: 'Delta Airlines', cabinClass: 'Economy', image: 'assets-travel/offer.png' },
    { id: 2, name: 'Flight 5678', from: 'Paris', to: 'Tokyo', date: '2024-07-10', price: '$200', status: 'Completed', passengers: 3, airline: 'Air France', cabinClass: 'Business', image: 'assets-travel/offer.png' },
  ];

  const renderBookings = (bookings, type) => {
    return bookings.map((booking) => (
      <div key={booking.bookingId} className="col-md-6 mb-4">
        <div className="card booking-card shadow-sm">
          <div className="row g-0">
            <div className="col-md-3">
              <img src={booking.image || 'assets-travel/detail4.png'} alt="" className="img-fluid rounded-start" />
            </div>
            <div className="col-md-9">
              <div className="card-body">
                <h5 className="card-title">{booking.name || "The Grand Palace"}</h5>
                <div className="row">
                  <div className="col-6">
                    <p className="card-text">
                      <strong>Date:</strong> {booking.date || booking.checkIndate}
                    </p>
                    <p className="card-text">
                      <strong>Price:</strong> {booking.price || 'A$ 151'}
                    </p>
                    {type === 'flights' && (
                      <>
                        <p className="card-text">
                          <strong>Airline:</strong> {booking.airline}
                        </p>
                        <p className="card-text">
                          <strong>Cabin Class:</strong> {booking.cabinClass}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="col-6">
                    <p className="card-text">
                      <strong>{type === 'hotel' ? 'Guests' : 'Passengers'}:</strong> {type === 'hotel' ? booking.adults : booking.passengers}
                    </p>
                    {type === 'flights' && (
                      <p className="card-text">
                        <strong>Route:</strong> {booking.from} to {booking.to}
                      </p>
                    )}
                    <p className={`card-text status-text ${booking.status?.toLowerCase()}`}>
                      <strong>Status:</strong> {booking.status || (booking.status= "Confirmed")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <Navbar />
      <div className="container booking-box mt-5">
        <div className="tab-buttons d-flex mb-4">
          <button
            className={`tab-button ${activeTab === 'hotel' ? 'active' : ''}`}
            onClick={() => setActiveTab('hotel')}
          >
            Hotel
          </button>
          <button
            className={`tab-button ${activeTab === 'flights' ? 'active' : ''}`}
            onClick={() => setActiveTab('flights')}
          >
            Flights
          </button>
        </div>

        <div className="row">
          {activeTab === 'hotel' && renderBookings(hotelBookings, 'hotel')}
          {activeTab === 'flights' && renderBookings(flightBookings, 'flights')}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Mybookings;
